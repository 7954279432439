import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { Banner, Skeleton, Spinner } from '@knack/asterisk-react';
import axios from 'axios';
import { type CredentialResponse } from 'google';
import Cookies from 'js-cookie';

import { usePostSignInActions } from '@/hooks/api/mutations/useSignInMutation';
import { GsiScriptState, useLoadGsiScript } from '@/hooks/useLoadGsiScript';
import { pushToDataLayer, shouldSendAnalytics } from '@/utils/analytics';
import { addNewUserToHubspot } from '@/utils/hubspot';
import { getEmbeddedId, getReferral } from '@/utils/querystrings';
import { cn } from '@/utils/tailwind';

interface Props {
  isSignUp?: boolean;
}

enum GoogleAuthType {
  SignIn = 'sign-in',
  SignUp = 'sign-up'
}

export const checkCookieSSO = (response: CredentialResponse) => {
  const knackAdCookie = Cookies.get('kn_ad');
  if (knackAdCookie) {
    const knAdCookieParts = knackAdCookie.split('~');
    return {
      ...response,
      gclid: knAdCookieParts[0],
      clientid: knAdCookieParts[1]
    };
  }
  return response;
};

export function GoogleButton({ isSignUp = false }: Props) {
  const runPostSignInActions = usePostSignInActions();
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
  const [error, setError] = useState('');
  const googleAuthContainerRef = useRef<HTMLDivElement>(null);

  /**
   * Attempts to create a new user with the provided Google credentials. If we already
   * have their email on record, we will instead sign them in.
   * @param response The response we get when user's sign in through the google auth button.
   */
  async function handleGoogle(response: CredentialResponse) {
    setIsGoogleAuthLoading(true);

    const url = `/v1/dashboard/accounts/google`;
    const responseWithCookieChecked = checkCookieSSO(response);
    try {
      // We need to use withCredentials for the user's session to be added properly
      const res = await axios.post(
        url,
        {
          ...responseWithCookieChecked,
          referral: getReferral(),
          embeddedId: getEmbeddedId(),
          googleAuthType: isSignUp ? GoogleAuthType.SignUp : GoogleAuthType.SignIn
        },
        { withCredentials: true }
      );

      if (res.data.success) {
        if (res.data.authType === GoogleAuthType.SignUp) {
          addNewUserToHubspot(res.data.session.user.email);

          if (shouldSendAnalytics(res.data.session.user.email)) {
            pushToDataLayer('signup-google');
          }
        }

        runPostSignInActions(res.data.session);
        setIsGoogleAuthLoading(false);
      }
    } catch (err: any) {
      const errorCode = err.response?.data?.errors[0]?.message;

      const errorMessage =
        !errorCode || errorCode === 'generic_error'
          ? 'errors.generic_error'
          : `components.auth.${errorCode}`;

      setError(errorMessage);
      setIsGoogleAuthLoading(false);
    }
  }

  const { scriptState } = useLoadGsiScript({
    onScriptLoadSuccess: () => {
      if (!window?.google || !googleAuthContainerRef.current) return;

      window.google.accounts?.id.initialize({
        client_id: import.meta.env.PUBLIC_GOOGLE_ID,
        callback: handleGoogle
      });
      window.google.accounts?.id.renderButton(googleAuthContainerRef.current, {
        size: 'large',
        text: isSignUp ? 'signup_with' : 'signin_with',
        theme: 'outline',
        type: 'standard',
        width: 275
      });
    },
    onScriptLoadError: () => {
      // eslint-disable-next-line no-console
      console.error('Failed to load Google SSO Script');
    }
  });

  return (
    <div className="mx-auto mb-6 flex flex-col" data-testid="auth-login-google-button">
      {scriptState === GsiScriptState.Loading && <Spinner className="flex h-[40px]" />}
      <div
        ref={googleAuthContainerRef}
        className={cn({
          'mx-auto flex h-[40px]': scriptState !== GsiScriptState.Loading,
          hidden: isGoogleAuthLoading
        })}
      />
      {isGoogleAuthLoading && <Skeleton className="mb-6 h-8 w-3/5 rounded-lg" />}
      {error && (
        <Banner intent="destructive" className="mt-5">
          <Banner.Message>
            <Trans i18nKey={error}>
              {error.includes('invalid_google_sign_in') && (
                <a
                  href={`${import.meta.env.PUBLIC_DASHBOARD_URL}/sign-up`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-emphasis underline"
                >
                  <strong>sign up</strong>
                </a>
              )}
            </Trans>
          </Banner.Message>
        </Banner>
      )}
    </div>
  );
}
