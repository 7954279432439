import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Button, Card } from '@knack/asterisk-react';

import { PLAN_FREQUENCY } from '@/types/plans';
import { useFreezeInfo } from '@/hooks/account/useFreezeInfo';
import { useAddonsPlanQuery } from '@/hooks/api/queries/useAddonsPlanQuery';
import { useApiPlanQuery } from '@/hooks/api/queries/useApiPlanQuery';
import { useIsTaxExempt } from '@/hooks/billing/useIsTaxExempt';
import { BILLING_PAGE_TABS } from '@/hooks/usePageTabs';
import { usePlans } from '@/hooks/usePlans';
import { getOrdinalNumber } from '@/utils/formatters';
import { getHasTrialExpired, getTrialEndDate } from '@/utils/trials';
import {
  currentApiAddonItems,
  legacyApiAddonItems,
  transactionAddonPacks
} from '@/pages/settings/billing/addons/addonsConstants';
import {
  checkAddons,
  getAddonPrice,
  getFinalPrice
} from '@/pages/settings/billing/addons/addonsHelper';

interface PlanDetailsProps {
  billingData: any;
  isSubscribed: boolean;
  trialDeadline: string;
  cardTitleClasses?: string;
  isHipaa: boolean;
}

export function PlanDetails({
  billingData,
  isSubscribed,
  trialDeadline,
  cardTitleClasses,
  isHipaa
}: PlanDetailsProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isFrozen } = useFreezeInfo();
  const { originalPlan, currentPlan } = usePlans();
  const { data: apiPlan } = useApiPlanQuery();
  const isTaxExempt = useIsTaxExempt();
  const { data: addons } = useAddonsPlanQuery();
  let planInterval = t('keywords.month');
  let planPrice = currentPlan?.price;
  if (currentPlan?.frequency === PLAN_FREQUENCY.Yearly) {
    planInterval = t('keywords.year');
    planPrice = planPrice ? planPrice * 10 : planPrice;
  }
  const apiAddonItems =
    apiPlan?.id !== 'api_241001_25' ? legacyApiAddonItems : currentApiAddonItems;
  const apiPlanWithDetails = apiAddonItems.find((item) => item.quantity === apiPlan?.quantity);
  const upcomingBillDate = billingData?.upcoming
    ? new Date(billingData.upcoming.period_end * 1000).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    : null;
  const getBillingMonthlyCycleMessage = (date: Date) => {
    const dayOfTheMonth = date.getDate();
    if (dayOfTheMonth === 31) {
      return t('components.billing.overview.billing_cycle_monthly_last_day');
    }

    return t('components.billing.overview.billing_cycle_monthly', {
      day: getOrdinalNumber(date.getDate())
    });
  };

  let planBillingCycleMessage;
  let apiPlanBillingCycleMessage;

  billingData?.customer?.subscriptions?.data?.forEach((subscription: any) => {
    // Check if the subscription matches the current product plan
    if (subscription.plan.id === currentPlan?.id) {
      const date = new Date(subscription.billing_cycle_anchor * 1000);
      if (currentPlan?.frequency === PLAN_FREQUENCY.Yearly) {
        planBillingCycleMessage = t('components.billing.overview.billing_cycle_yearly', {
          date: date.toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric'
          })
        });
      } else {
        planBillingCycleMessage = getBillingMonthlyCycleMessage(date);
      }
    }

    // Check if the subscription matches the current API add-on plan
    if (subscription.plan.id === apiPlan?.id) {
      const date = new Date(subscription.billing_cycle_anchor * 1000);
      apiPlanBillingCycleMessage = getBillingMonthlyCycleMessage(date);
    }
  });

  const getAddOnItem = (plan) => {
    if (plan.id.startsWith('sso_') || plan.id.startsWith('seo_')) {
      return (
        <div key={plan.id}>
          <p className="mb-1">
            {t('components.billing.overview.addon_current_plan_info', {
              quantity: `${plan.quantity.toLocaleString()}`,
              price: getAddonPrice('sso', plan.id).toLocaleString(),
              type: `${t(`components.billing.overview.addon_type_info.${plan.id.startsWith('sso_') ? 'sso' : 'seo'}`)}`,
              frequency: 'month'
            })}
            <span className="ml-1 text-sm text-subtle">{planBillingCycleMessage}</span>
          </p>
        </div>
      );
    }
    switch (plan.type) {
      case 'api_limit_extra':
        return (
          <div key={plan.id}>
            <p className="mb-1">
              {t('components.billing.overview.addon_current_plan_info', {
                quantity: apiPlanWithDetails?.calls.toLocaleString(),
                type: `${t('components.billing.overview.addon_type_info.api_calls')}`,
                price: getAddonPrice('api', plan.id, apiPlanWithDetails).toLocaleString(),
                frequency: 'month'
              })}{' '}
              <span className="ml-1 text-sm text-subtle">{apiPlanBillingCycleMessage}</span>
            </p>
          </div>
        );
      case 'records_limit_extra':
        return (
          <p className="mb-1">
            {t('components.billing.overview.addon_current_plan_info', {
              quantity: plan.quantity.toLocaleString(),
              type: `${t('components.billing.overview.addon_type_info.records')}`,
              price: getAddonPrice('records', plan.id).toLocaleString(),
              frequency: 'month'
            })}
            <span className="ml-1 text-sm text-subtle">{planBillingCycleMessage}</span>
          </p>
        );
      case 'storage_limit_extra':
        return (
          <div key={plan.id}>
            <p className="mb-1">
              {t('components.billing.overview.addon_current_plan_info', {
                quantity: `${plan.quantity.toLocaleString()} GB`,
                type: `${t('components.billing.overview.addon_type_info.storage')}`,
                price: getAddonPrice('storage', plan.id).toLocaleString(),
                frequency: 'month'
              })}
              <span className="ml-1 text-sm text-subtle">{planBillingCycleMessage}</span>
            </p>
          </div>
        );
      case 'transactions_limit_extra':
        return (
          <div key={plan.id}>
            <p className="mb-1">
              {t('components.billing.overview.addon_current_plan_info', {
                quantity: `${plan.quantity.toLocaleString()} x ${(
                  Object.values(transactionAddonPacks).find(
                    (pack) => pack.testId === plan.id || pack.prodId === plan.id
                  )?.transactions ?? 0
                ).toLocaleString()}`,
                price: getAddonPrice('transactions', plan.id).toLocaleString(),
                type: `${t('components.billing.overview.addon_type_info.transactions')}`,
                frequency: 'month'
              })}
              <span className="ml-1 text-sm text-subtle">{planBillingCycleMessage}</span>
            </p>
          </div>
        );
      default:
        return <p className="mb-1">{t('components.billing.overview.no_addons')}</p>;
    }
  };

  const renderAddOns = () => {
    const addonTypes = ['api', 'records', 'storage', 'transactions', 'sso', 'seo'];
    if (addons) {
      return addonTypes.map((type) => {
        if (addons[type]?.length > 0) {
          return addons[type].map((plan) => getAddOnItem(plan));
        }
        return null;
      });
    }
    return null;
  };

  return (
    <Card className="flex flex-col p-6 sm:p-6">
      <div className="flex justify-between">
        <h2 className={cardTitleClasses}>{t('components.billing.overview.plan_details')}</h2>
        {isFrozen && (
          <Badge intent="warning" className="self-start text-xs normal-case">
            {t('components.account.frozen')}
          </Badge>
        )}
        {!isFrozen && isTaxExempt && (
          <Badge className="self-start text-xs normal-case">
            {t('components.billing.overview.tax_exempt')}
          </Badge>
        )}
      </div>

      {isSubscribed && (
        <>
          <div className="mb-6" data-testid="plan-overview-current-plan">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.current_plan')}
            </span>
            <p className="mb-1">
              {originalPlan?.name}{' '}
              {t('components.billing.overview.tax', {
                a: `$${Number(getFinalPrice(billingData, planPrice)).toLocaleString()}`,
                b: planInterval
              })}
            </p>
            <span className="text-subtle">{planBillingCycleMessage}</span>
          </div>

          <div className="mb-6">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.addons')}
            </span>
            {addons && checkAddons(addons) ? (
              renderAddOns()
            ) : (
              <p className="mb-1">{t('components.billing.overview.no_addons')}</p>
            )}
          </div>
          <div className="mb-6">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.next_invoice')}
            </span>
            <p>{upcomingBillDate}</p>
          </div>
          <div className="mt-auto flex flex-wrap gap-2">
            <Button
              intent="secondary"
              size="sm"
              disabled={isHipaa}
              className="grow"
              onClick={() =>
                navigate({
                  pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.Plans.path}`,
                  search
                })
              }
            >
              {t('components.billing.overview.manage_plan')}
            </Button>
            <Button
              intent="secondary"
              size="sm"
              className="grow"
              onClick={() =>
                navigate({
                  pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.Invoices.path}`,
                  search
                })
              }
            >
              {t('components.billing.overview.view_invoices')}
            </Button>
          </div>
        </>
      )}

      {!isSubscribed && (
        <>
          <div className="mb-6" data-testid="plan-overview-current-plan">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.current_plan')}
            </span>
            <p>{originalPlan?.name}</p>
          </div>
          <div className="mb-6" data-testid="plan-overview-trial-ends">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.trial_ends')}
            </span>
            <p>{getTrialEndDate(trialDeadline)}</p>
            {getHasTrialExpired(trialDeadline) && (
              <p className="mt-1 text-destructive">
                {t('components.billing.overview.trial_expired')}
              </p>
            )}
          </div>
          <div className="mt-auto flex">
            <Button
              size="sm"
              className="grow"
              onClick={() =>
                navigate({
                  pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.Plans.path}`,
                  search
                })
              }
            >
              {t('actions.upgrade')}
            </Button>
          </div>
        </>
      )}
    </Card>
  );
}
