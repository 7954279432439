import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import { HiChevronLeft as ArrowLeft } from 'react-icons/hi2';
import { Button, ThemeProviderContext } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { useSession } from '@/hooks/useSession';
import { useSetupWizardLogic } from '@/hooks/useSetupWizardLogic';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import { AppCreationErrorBanner } from '@/pages/apps/create-app/setup-wizard-variations/AppCreationErrorBanner';
import { translationKeyPrefixVerticalUI } from '@/pages/apps/create-app/setup-wizard-variations/constants';
import { trackEventAiClicked } from '@/pages/apps/create-app/setup-wizard-variations/helper';
import { TemplateCardVerticalUI } from './TemplateCardVerticalUI';

interface StepSelectCardProps {
  selectedIndustry: string;
  selectedUseCase: string;
  sampleApps: SampleApp[];
  onGoBack: () => void;
}

export function StepSelectCardVerticalUI({
  selectedIndustry,
  selectedUseCase,
  sampleApps,
  onGoBack
}: StepSelectCardProps) {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const { t } = useTranslation();
  const session = useSession();
  const {
    templatesToDisplay,
    handleSampleCreateApp,
    handleAppCreation,
    selectedTemplate,
    setSelectedTemplate,
    appCreationError,
    shouldShowAiModal,
    setShouldShowAiModal,
    isPending
  } = useSetupWizardLogic(selectedIndustry, selectedUseCase, sampleApps);

  if (isPending) {
    return <FullPageSpinner />;
  }

  return (
    <div className="flex flex-col items-center gap-6" data-testid="step-select-card">
      {appCreationError && (
        <AppCreationErrorBanner
          appCreationError={appCreationError}
          data-testid="app-creation-error-banner"
        />
      )}
      <div className="flex w-full max-w-[600px] flex-col items-center gap-6 pb-2">
        {templatesToDisplay.map((template) => (
          <TemplateCardVerticalUI
            data-testid={`${template.slug}-card`}
            key={template.id}
            type="template"
            template={template}
            onTemplateCardClick={handleSampleCreateApp}
            isTemplateSelected={selectedTemplate?.id === template.id}
            onClick={() => setSelectedTemplate(template)}
          />
        ))}

        <TemplateCardVerticalUI
          type="import"
          isDarkMode={isDarkMode}
          onClick={() => handleAppCreation('import')}
        />
      </div>
      <div className="mb-4 flex w-full max-w-[600px] justify-between">
        <Button
          intent="minimal"
          className="justify-start"
          onClick={onGoBack}
          data-testid="back-button"
        >
          <ArrowLeft />
          {t('actions.back')}
        </Button>

        <Button
          onClick={() => {
            trackEventAiClicked(session.account.id);
            void handleAppCreation('ai');
          }}
          className="items-center justify-end"
          intent="secondary"
          data-testid="skip-and-build-database"
        >
          <AiStarsIcon className="mr-2 rotate-90" />
          {t(`${translationKeyPrefixVerticalUI}.step_2.skip_and_generate_with_ai`)}
        </Button>
      </div>
      {shouldShowAiModal && (
        <AiModal open onClose={() => setShouldShowAiModal(false)} data-testid="ai-modal" />
      )}
    </div>
  );
}
