import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Banner, Button, Card, Switch, useToast } from '@knack/asterisk-react';

import { type Account, type ProductPlan } from '@/types/account';
import { PLAN_FREQUENCY, PLAN_TYPE, type PlanFrequency, type PlanType } from '@/types/plans';
import { useUpdatePlanMutation } from '@/hooks/api/mutations/useUpdatePlanMutation';
import { useIsDowngradeAllowedQuery } from '@/hooks/api/queries/useIsDowngradeAllowedQuery';
import { usePlansQuery } from '@/hooks/api/queries/usePlansQuery';
import { usePlans } from '@/hooks/usePlans';
import { shouldSendAnalytics } from '@/utils/analytics';
import { cn } from '@/utils/tailwind';
import { ConfirmPlanDialog } from './ConfirmPlanDialog';
import { Dialog as HearAboutUsDialog } from './hear-about-us/Dialog';
import { PlanCard } from './PlanCard';
import { PlansSkeleton } from './PlansSkeleton';

interface PlansProps {
  accountData: Account;
  hasPaymentMethod: boolean;
}

export function Plans({ accountData, hasPaymentMethod }: PlansProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { data: knackPlans, isLoading } = usePlansQuery();
  const updatePlanMutation = useUpdatePlanMutation();

  const [planType, setPlanType] = useState<PlanType>(PLAN_TYPE.Basic);
  const [planFrequency, setPlanFrequency] = useState<PlanFrequency>(PLAN_FREQUENCY.Yearly);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isHearAboutUsDialogOpen, setIsHearAboutUsDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<ProductPlan | null>(null);

  const isDowngradeAllowed = useIsDowngradeAllowedQuery(accountData.id);
  const { originalPlan } = usePlans();

  const isCurrentPlanEnterprise = !!originalPlan?.id.includes(PLAN_TYPE.Enterprise);

  const monthlyPlansBasic = useMemo(
    () =>
      knackPlans?.filter(
        (plan) =>
          plan.frequency !== PLAN_FREQUENCY.Yearly &&
          !plan.id.includes(PLAN_TYPE.Enterprise) &&
          !plan.id.includes(PLAN_TYPE.Agency)
      ) ?? [],
    [knackPlans]
  );
  const monthlyPlansEnterprise = useMemo(
    () =>
      knackPlans?.filter(
        (plan) => plan.frequency !== PLAN_FREQUENCY.Yearly && plan.id.includes(PLAN_TYPE.Enterprise)
      ) ?? [],
    [knackPlans]
  );
  const monthlyPlansAgency = useMemo(
    () =>
      knackPlans?.filter(
        (plan) => plan.frequency !== PLAN_FREQUENCY.Yearly && plan.id.includes(PLAN_TYPE.Agency)
      ) ?? [],
    [knackPlans]
  );
  const yearlyPlansBasic = useMemo(
    () =>
      knackPlans?.filter(
        (plan) =>
          plan.frequency === PLAN_FREQUENCY.Yearly &&
          !plan.id.includes(PLAN_TYPE.Enterprise) &&
          !plan.id.includes(PLAN_TYPE.Agency)
      ) ?? [],
    [knackPlans]
  );
  const yearlyPlansEnterprise = useMemo(
    () =>
      knackPlans?.filter(
        (plan) => plan.frequency === PLAN_FREQUENCY.Yearly && plan.id.includes(PLAN_TYPE.Enterprise)
      ) ?? [],
    [knackPlans]
  );
  const yearlyPlansAgency = useMemo(
    () =>
      knackPlans?.filter(
        (plan) => plan.frequency === PLAN_FREQUENCY.Yearly && plan.id.includes(PLAN_TYPE.Agency)
      ) ?? [],
    [knackPlans]
  );

  let plansToDisplay: ProductPlan[] = [];
  if (planFrequency === PLAN_FREQUENCY.Monthly) {
    if (planType === PLAN_TYPE.Agency) {
      plansToDisplay = monthlyPlansAgency;
    } else if (planType === PLAN_TYPE.Enterprise) {
      plansToDisplay = monthlyPlansEnterprise;
    } else {
      plansToDisplay = monthlyPlansBasic;
    }
  } else if (planFrequency === PLAN_FREQUENCY.Yearly) {
    if (planType === PLAN_TYPE.Agency) {
      plansToDisplay = yearlyPlansAgency;
    } else if (planType === PLAN_TYPE.Enterprise) {
      plansToDisplay = yearlyPlansEnterprise;
    } else {
      plansToDisplay = yearlyPlansBasic;
    }
  }

  useEffect(() => {
    // Determine the current plan's type and frequency, so we show the correct plans on load
    // Note: frequency is undefined on monthly plans so the comparison has to be always on yearly
    setPlanFrequency(
      originalPlan?.frequency === PLAN_FREQUENCY.Yearly
        ? PLAN_FREQUENCY.Yearly
        : PLAN_FREQUENCY.Monthly
    );

    if (originalPlan?.id.includes(PLAN_TYPE.Agency)) {
      setPlanType(PLAN_TYPE.Agency);
    } else if (originalPlan?.id.includes(PLAN_TYPE.Enterprise)) {
      setPlanType(PLAN_TYPE.Enterprise);
    } else {
      setPlanType(PLAN_TYPE.Basic);
    }
  }, [originalPlan?.frequency, originalPlan?.id]);

  if (!knackPlans && isLoading) {
    return <PlansSkeleton />;
  }

  const togglePlanFrequency = () => {
    setPlanFrequency((prev) =>
      prev === PLAN_FREQUENCY.Monthly ? PLAN_FREQUENCY.Yearly : PLAN_FREQUENCY.Monthly
    );
  };

  const onPlanSelect = (plan: ProductPlan) => {
    setSelectedPlan(plan);
    setIsConfirmDialogOpen(true);
  };

  const onPlanChangeConfirm = () => {
    if (!selectedPlan?.id) return;

    updatePlanMutation.mutate(selectedPlan.id, {
      onSuccess: () => {
        presentToast({
          title: `${selectedPlan.level > (originalPlan?.level || 0) ? '🎉 ' : ''}${t(
            'components.billing.plans.plan_successfully_updated'
          )}`
        });
        setIsConfirmDialogOpen(false);
        void isDowngradeAllowed.refetch();
      },
      onError: () => {
        presentToast({
          title: t('errors.generic_error')
        });
        setIsConfirmDialogOpen(false);
      }
    });
  };

  const onTrialerConverted = () => {
    if (shouldSendAnalytics(accountData?.session.user?.email)) {
      setIsHearAboutUsDialogOpen(true);
    }
  };

  const checkIfDowngradeIsAllowed = () => isDowngradeAllowed.data?.enabled ?? true;

  return (
    <>
      <div className="mb-6">
        <div className="mb-3 flex flex-wrap items-center justify-between gap-3">
          <div
            className={
              planType === PLAN_TYPE.Agency
                ? 'flex gap-3'
                : 'flex gap-3 rounded-lg border border-default p-1'
            }
          >
            {planType !== PLAN_TYPE.Agency && (
              <>
                <Button
                  intent="minimal"
                  data-testid="plan-type-basic"
                  className={cn('rounded-lg px-3 py-1.5 text-emphasis hover:bg-subtle', {
                    'bg-emphasis hover:bg-emphasis': planType === PLAN_TYPE.Basic
                  })}
                  onClick={() => setPlanType(PLAN_TYPE.Basic)}
                >
                  {t('components.billing.plans.standard')}
                </Button>
                <Button
                  intent="minimal"
                  data-testid="plan-type-enterprise"
                  className={cn('rounded-lg px-3 py-1.5 text-emphasis hover:bg-subtle', {
                    'bg-emphasis hover:bg-emphasis': planType === PLAN_TYPE.Enterprise
                  })}
                  onClick={() => setPlanType(PLAN_TYPE.Enterprise)}
                >
                  {t('components.billing.plans.plus')}
                </Button>
              </>
            )}
          </div>
          <div className="flex gap-3">
            <span
              className={
                planFrequency === PLAN_FREQUENCY.Monthly
                  ? 'font-medium text-emphasis'
                  : 'text-subtle'
              }
            >
              {t('components.billing.plans.billed_monthly_switch')}
            </span>
            <Switch
              data-testid="plan-frequency-switch"
              onCheckedChange={togglePlanFrequency}
              checked={planFrequency === PLAN_FREQUENCY.Yearly}
            />
            <span
              className={
                planFrequency === PLAN_FREQUENCY.Yearly
                  ? 'font-medium text-emphasis'
                  : 'text-subtle'
              }
            >
              {t('components.billing.plans.billed_yearly_switch')}
              <span className="text-xs text-brand">
                {t('components.billing.plans.get_two_months_free')}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div>
        {accountData.product_plan?.id.includes('_y') && !checkIfDowngradeIsAllowed() && (
          <Banner
            intent="warning"
            className="mb-6"
            closeMode="text"
            closeLabel={t('actions.dismiss')}
            actionLabel={t('actions.dismiss')}
          >
            <Banner.Message>
              <span>{t('components.billing.plans.banner_yearly_plan')}</span>
            </Banner.Message>
          </Banner>
        )}
      </div>
      <div className="grid items-end gap-6 lg:grid-cols-2 xl:grid-cols-4">
        {plansToDisplay
          ?.sort((a, b) => a.level - b.level)
          .map((knackPlan) => (
            <PlanCard
              key={knackPlan.id}
              knackPlan={knackPlan}
              originalPlan={originalPlan}
              planFrequency={planFrequency}
              onPlanSelect={onPlanSelect}
              isDowngradeAllowed={checkIfDowngradeIsAllowed()}
            />
          ))}

        <Card className="flex h-[400px] flex-col p-6 sm:p-6" data-testid="change-plan-custom">
          <h2 className="mb-6 text-xl font-medium text-emphasis">
            {t('components.billing.plans.custom')}
          </h2>
          <p className="mb-6">
            {isCurrentPlanEnterprise ? (
              t('components.billing.plans.looking_to_upgrade')
            ) : (
              <Trans i18nKey="components.billing.plans.need_custom_plan">
                Looking for an
                <span className="font-semibold">Enterprise, GovCloud, or HIPAA plan?</span> Our
                Sales team would love help you.
              </Trans>
            )}
          </p>
          <Button asChild className="mt-auto">
            <a href="mailto:sales@knack.com">{t('components.billing.plans.contact_sales')}</a>
          </Button>
        </Card>
      </div>

      <div className="mt-4 flex items-center">
        <a
          href="http://knack.com/pricing"
          className="inline-block font-medium underline"
          target="_blank"
          rel="noreferrer"
        >
          {t('components.billing.plans.features_between_plans')}
        </a>
      </div>
      <div className="mt-4 flex items-center">
        <a
          href="http://knack.com/discounts"
          className="inline-block font-medium underline"
          target="_blank"
          rel="noreferrer"
        >
          {t('components.billing.plans.learn_about_discounts')}
        </a>
      </div>

      {selectedPlan && (
        <ConfirmPlanDialog
          open={isConfirmDialogOpen}
          setIsOpen={setIsConfirmDialogOpen}
          selectedPlan={selectedPlan}
          accountData={accountData}
          hasPaymentMethod={hasPaymentMethod}
          isLoading={updatePlanMutation.isPending}
          closeDialog={() => setIsConfirmDialogOpen(false)}
          onCancel={() => setIsConfirmDialogOpen(false)}
          onPlanChangeConfirm={onPlanChangeConfirm}
          onTrialerConverted={onTrialerConverted}
        />
      )}

      <HearAboutUsDialog open={isHearAboutUsDialogOpen} setIsOpen={setIsHearAboutUsDialogOpen} />
    </>
  );
}
