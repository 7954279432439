import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Spinner, Switch, Tooltip } from '@knack/asterisk-react';

import { type AddonsType } from '@/enums/addons';
import { useAddonPlanMutation } from '@/hooks/api/mutations/useAddonMutation';
import {
  transactionAddOnAutoIncreasePercentage,
  transactionAddonPacks
} from '@/pages/settings/billing/addons/addonsConstants';
import { ConfirmDisableAutoIncreaseDialog } from './ConfirmDisableAutoIncreaseDialog';

type AutoIncreaseProps = {
  addonType: AddonsType;
  checked: boolean;
  disabled: boolean;
  renewalDay: number;
};

export function AutoIncrease({ addonType, checked, disabled, renewalDay }: AutoIncreaseProps) {
  const [t] = useTranslation();
  const [isSwitchChecked, setIsSwitchChecked] = useState(checked);
  const hasCheckAndDisableBeenChecked = useRef(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { changeAutoIncreaseMutation } = useAddonPlanMutation();
  const isLoading = changeAutoIncreaseMutation.isPending;

  const handleAutoIncreaseMutation = () => {
    changeAutoIncreaseMutation.mutate({
      autoIncrease: !isSwitchChecked,
      planType: addonType
    });
    setIsDialogOpen(false);
    setIsSwitchChecked(!isSwitchChecked);
  };

  // sometimes it might be a disparity between the checked and disabled props
  // due product requirements, when the checked prop is true because it was allowed on a first time
  // to have the autoincreased enabled, but now they want to disable it, so we may find that the switch
  // is disabled but checked, so we need to handle the mutation to disable it, e.g. custom plus users handled by Steve
  if (checked && disabled && !hasCheckAndDisableBeenChecked.current) {
    handleAutoIncreaseMutation();
    hasCheckAndDisableBeenChecked.current = true;
  }

  if (checked && disabled && !hasCheckAndDisableBeenChecked.current) {
    return <Spinner size="sm" />;
  }

  const onSwitchChange = () => {
    if (isSwitchChecked) {
      setIsDialogOpen(true);
    } else {
      handleAutoIncreaseMutation();
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="mb-6 justify-between">
      <Switch
        disabled={disabled}
        data-testid="auto-increase-transactions-switch"
        onCheckedChange={onSwitchChange}
        checked={isSwitchChecked}
      />
      <span className={`ml-1 align-top ${checked ? 'text-emphasis' : 'text-muted'}`}>
        {t('components.billing.overview.flows.switch_title')}
      </span>
      {disabled && (
        <Tooltip>
          <Tooltip.Trigger className="ml-1 inline-block max-w-[30ch] truncate text-subtle">
            <InfoIcon className="text-subtle" size={18} />
          </Tooltip.Trigger>
          <Tooltip.Content>
            {t('components.billing.overview.flows.disabled_tooltip')}
          </Tooltip.Content>
        </Tooltip>
      )}
      <span className="mt-2 block pl-9 text-sm text-subtle">
        {t('components.billing.overview.flows.switch_description', {
          day: renewalDay,
          transactions: transactionAddonPacks['tier-1'].transactions.toLocaleString(),
          priceInDollars: transactionAddonPacks['tier-1'].price.toLocaleString(),
          autoIncreasePercentage: transactionAddOnAutoIncreasePercentage
        })}
      </span>

      {isDialogOpen && (
        <ConfirmDisableAutoIncreaseDialog
          open={isDialogOpen}
          isLoading={isLoading}
          onClose={handleDialogClose}
          onConfirm={handleAutoIncreaseMutation}
        />
      )}
    </div>
  );
}
