import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineExternalLink as ExternalIcon } from 'react-icons/hi';
import { HiOutlineSquares2X2 as SampleMarketplaceIcon } from 'react-icons/hi2';
import { MdClose as CloseIcon } from 'react-icons/md';
import { Button, Card, Skeleton } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { type SampleApp, type SampleApp as SampleAppType } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useSession } from '@/hooks/useSession';
import { useTranslationArray } from '@/hooks/useTranslationArray';
import { useWelcomePathAutomation } from '@/hooks/useWelcomePathAutomation';
import { shouldSendAnalytics } from '@/utils/analytics';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { getSampleWelcomePath } from '@/utils/samples';
import { LoadingModal } from '@/components/ui/LoadingModal';
import { DEFAULT_SAMPLES, WelcomePath } from '@/pages/apps/create-app/constants';
import { CreateAppFromSampleModal } from '@/pages/apps/create-app/CreateAppFromSampleModal';
import { ILLUSTRATIONS } from '@/pages/apps/create-app/illustrations/constants';
import { SampleAppButton } from '@/pages/apps/create-app/SampleAppButton';

export function AppSampleLibrary({
  isDismissible,
  isHidden,
  isDisabled,
  onOpenRestrictionModal,
  setAppCreationError
}: {
  isDismissible: boolean;
  isHidden: boolean;
  isDisabled?: boolean;
  onOpenRestrictionModal: () => void;
  setAppCreationError: (error: string) => void;
}) {
  const [t] = useTranslation();
  const { data: samples, isLoading } = useSampleAppsQuery();
  const { createAppFromSample } = useAppMutation();
  const session = useSession();
  const [shouldShowAppSampleModal, setShouldShowAppSampleModal] = useState(false);
  const [selectedSampleTitle, setSelectedSampleTitle] = useState('');
  const basePhrases = useTranslationArray('components.create_app.creating');
  const creatingPhrases = useMemo(
    () => [
      t('components.create_app.creating_template', { template: selectedSampleTitle }),
      ...basePhrases
    ],
    [t, selectedSampleTitle, basePhrases]
  );
  const [isDismissed, setIsDismissed] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.gettingStartedDismissed,
    false
  );
  const automation = useWelcomePathAutomation();

  const handleInstallSample = (sample: SampleAppType) => {
    setAppCreationError('');
    setSelectedSampleTitle(sample.title);
    const data = {
      appId: sample.id,
      templateSlug: sample.slug,
      appDescription: sample.description
    };
    createAppFromSample.mutate(data, {
      onSuccess: async (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          await rudderStackAnalytics.track('New App From Sample Created', {
            app: sample.title,
            groupId: session.account.id
          });
        }
        window.location.href = `${isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access') ? import.meta.env.PUBLIC_BUILDER_NEXT_URL : import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
          response.app.slug
        }/${getSampleWelcomePath(sample)}`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      },
      onSettled: () => {
        setSelectedSampleTitle('');
      }
    });
  };

  useEffect(() => {
    if (automation && automation.path === WelcomePath.Template) {
      if (!samples || isDisabled || createAppFromSample.isPending) {
        return;
      }

      const template = samples.find((s: SampleAppType) => s.id === automation.extra.templateId);

      if (!template) {
        return;
      }

      handleInstallSample(template);
    }
  }, [automation, isDisabled, samples]);

  if (isDismissed || isHidden) {
    return null;
  }

  return (
    <Card className="relative flex flex-col gap-4 sm:p-6">
      {isDismissible && (
        <Button
          intent="minimalStandalone"
          onClick={() => setIsDismissed(true)}
          className="absolute right-4 top-4.5 p-2 opacity-70 transition-opacity hover:opacity-100"
        >
          <Button.Icon icon={CloseIcon} />
        </Button>
      )}

      <div className="flex justify-between gap-2">
        <h3 className="text-xl font-medium text-emphasis">
          {t('components.template_library.title')}
        </h3>
        <Button intent="link" className="mr-7 gap-1" asChild>
          <a href="https://www.knack.com/template-inventory/" target="_blank" rel="noreferrer">
            {t('components.template_library.learn')}
            <Button.Icon icon={ExternalIcon} />
          </a>
        </Button>
      </div>
      <div className="grid grid-cols-2 flex-row gap-4 sm:grid-cols-3 lg:flex">
        {createAppFromSample.isPending && <LoadingModal phrases={creatingPhrases} />}

        {isLoading &&
          DEFAULT_SAMPLES.map((sampleId: string) => (
            <div key={sampleId} className="flex flex-1 flex-col gap-2 lg:w-0">
              <Skeleton className="h-24" />
              <Skeleton className="h-4" />
            </div>
          ))}

        {!isLoading &&
          samples
            .filter((sample: SampleApp) => DEFAULT_SAMPLES.includes(sample.slug))
            .map((sample: SampleApp) => (
              <SampleAppButton
                key={sample.id}
                title={sample.title}
                isLoading={isLoading}
                illustration={ILLUSTRATIONS[sample.icon] ?? ILLUSTRATIONS.dashboard}
                onClick={() =>
                  isDisabled ? onOpenRestrictionModal() : handleInstallSample(sample)
                }
              />
            ))}

        <button
          type="button"
          onClick={() =>
            isDisabled
              ? onOpenRestrictionModal()
              : setShouldShowAppSampleModal(!createAppFromSample.isPending)
          }
          className="flex flex-1 cursor-pointer flex-col gap-2"
        >
          <div className="relative flex h-full min-h-12 w-full items-center justify-center overflow-hidden rounded-md bg-muted text-brand lg:min-h-16 xl:min-h-20">
            <SampleMarketplaceIcon size={48} strokeWidth={0.75} />
          </div>
          <div className="text-xs font-medium">{t('components.template_library.all')}</div>
        </button>
      </div>

      <CreateAppFromSampleModal
        open={shouldShowAppSampleModal}
        setIsOpen={setShouldShowAppSampleModal}
      />
    </Card>
  );
}
