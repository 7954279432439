import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Dialog, useToast } from '@knack/asterisk-react';
import sanitizationHelper from '@knack/sanitization-helper';
import axios from 'axios';
import { z } from 'zod';

import { type User } from '@/types/user';
import { useAccountMutation, type AccountPayload } from '@/hooks/api/mutations/useAccountMutation';
import { useUserMutation } from '@/hooks/api/mutations/useUserMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useIsSharedBuilderOnly, useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { zodEmailRegex } from '@/utils/zod';
import { ProfileAvatar } from '@/pages/settings/account/ProfileAvatar';
import { AccountInfoForm } from './AccountInfoForm';
import { AccountOwnerInfoForm } from './AccountOwnerInfoForm';
import { ConfirmAccountInfoChangeModal } from './ConfirmAccountInfoChangeModal';

interface AccountOwnerInfoFormDataProps extends User {
  password?: string;
}

export function Overview() {
  const [t] = useTranslation();
  const session = useSession();
  const { data: account } = useAccountQuery();
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { presentToast } = useToast();

  const { mutate: updateUser, isPending: isUpdateUserSaving } = useUserMutation();
  const { mutate: updateAccount, isPending: isUpdateAccountSaving } = useAccountMutation();

  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isGoogleUser = session?.user?.isGoogleUser;

  const accountOwnerInfoFormSchema = z.object({
    firstName: z
      .string()
      .min(1, t('components.auth.first_name_required'))
      .refine(
        (firstName) => firstName === sanitizationHelper.sanitizePersonName(firstName),
        t('components.auth.first_name_alpha_only')
      ),
    lastName: z
      .string()
      .min(1, t('components.auth.last_name_required'))
      .refine(
        (lastName) => lastName === sanitizationHelper.sanitizePersonName(lastName),
        t('components.auth.last_name_alpha_only')
      ),
    email: z
      .string()
      .min(1, t('components.auth.email_required'))
      .regex(zodEmailRegex, t('components.auth.email_invalid')),
    password: z.optional(z.string().min(1, t('components.auth.password_required')))
  });
  type FormSchemaType = z.infer<typeof accountOwnerInfoFormSchema>;

  const {
    register: registerAccountOwnerInfoForm,
    formState: accountOwnerInfoFormState,
    handleSubmit: handleSubmitAccountOwnerInfo,
    getValues: getAccountOwnerInfoFormValues,
    reset: resetAccountOwnerForm
  } = useForm<FormSchemaType>({
    resolver: zodResolver(accountOwnerInfoFormSchema),
    defaultValues: {
      firstName: session.user?.firstName,
      lastName: session.user?.lastName,
      email: session.user?.email
    }
  });

  const accountInfoFormSchema = z.object({
    name: z.string().min(1, t('components.account.name_required')),
    slug: z.string().regex(/^[a-zA-Z0-9_~.-]+$/),
    settings: z.object({
      flows: z.object({
        timezone: z.string(),
        language: z.string()
      })
    })
  });

  const {
    register: registerAccountInfoForm,
    formState: accountInfoFormState,
    handleSubmit: handleSubmitAccountInfo,
    reset: resetAccountInfoForm,
    getValues: getAccountInfoFormValues,
    control
  } = useForm<z.infer<typeof accountInfoFormSchema>>({
    resolver: zodResolver(accountInfoFormSchema),
    defaultValues: {
      name: account?.name,
      slug: account?.slug,
      settings: {
        flows: {
          timezone: account?.settings?.flows?.timezone,
          language: account?.settings?.flows?.language
        }
      }
    }
  });

  function onSubmitAccountInfo(formData: Partial<AccountPayload>) {
    updateAccount(
      {
        id: session.account.id,
        name: formData.name,
        slug: formData.slug,
        status: session.account.status,
        settings: formData.settings
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.account.update_success')
          });
          resetAccountInfoForm(formData, {
            keepDirtyValues: false
          });
          setShowPasswordDialog(false);
          setErrorMessage('');
        },
        onError: (error) => {
          const message = getErrorMessage(error, t('components.account.update_error'));
          setErrorMessage(message);
          setShowPasswordDialog(false);
        }
      }
    );
  }

  function onSubmitAccountOwnerInfo(accountOwnerInfoFormData: AccountOwnerInfoFormDataProps) {
    const { password, ...user } = accountOwnerInfoFormData;

    updateUser(
      {
        user,
        password
      },
      {
        onSuccess: () => {
          // If the user has changed account info, it will trigger the call onSubmitAccountInfo and present the toast.
          // So we won't present the toast twice.
          if (!accountInfoFormState.isDirty) {
            presentToast({
              title: t('components.account.update_success')
            });
          }
          resetAccountOwnerForm(
            { ...accountOwnerInfoFormData, password: undefined },
            {
              keepDirtyValues: false
            }
          );

          setShowPasswordDialog(false);
          setErrorMessage('');
        },
        onError: (error) => {
          let message = t('components.account.update_error');
          if (axios.isAxiosError(error)) {
            if (error.response?.data?.errors?.[0]?.message) {
              if (error.response.data?.errors?.[0].message === 'Current password is incorrect.') {
                message = t('components.account.invalid_password');
              } else {
                message = error.response?.data?.errors[0]?.message;
              }
            }
          }
          setErrorMessage(message);
          setShowPasswordDialog(false);
        }
      }
    );
  }

  const handlePasswordDialogClose = () => {
    setShowPasswordDialog(false);
    resetAccountOwnerForm();
    resetAccountInfoForm({
      name: account?.name,
      slug: account?.slug
    });
  };

  function handleSaveChanges() {
    const { email } = getAccountOwnerInfoFormValues();
    const { slug } = getAccountInfoFormValues();

    if (
      ((session.account?.slug !== slug && !isSharedBuilderOnly) || session.user?.email !== email) &&
      !showPasswordDialog
    ) {
      setShowPasswordDialog(true);
      return;
    }

    if (accountInfoFormState.isDirty) {
      void handleSubmitAccountInfo(onSubmitAccountInfo)();
    }

    if (accountOwnerInfoFormState.isDirty) {
      // We cancel the submit if the other form is not valid
      if (accountInfoFormState.isDirty && !accountInfoFormState.errors) {
        handlePasswordDialogClose();
        return;
      }
      void handleSubmitAccountOwnerInfo((data) =>
        onSubmitAccountOwnerInfo({
          ...session.user,
          ...data
        })
      )();
    }
  }

  const handleSubmit = handleSubmitAccountOwnerInfo(
    () => handleSaveChanges(),
    // In case errors are present, we close the dialog
    () => {
      if (!accountOwnerInfoFormState?.errors?.password) return;
      handlePasswordDialogClose();
    }
  );

  const isDirty = accountOwnerInfoFormState.isDirty || accountInfoFormState.isDirty;
  const isSaving = isUpdateAccountSaving || isUpdateUserSaving;

  return (
    <>
      {errorMessage && (
        <Banner intent="destructive" icon={ExclamationIcon} className="mb-2">
          <Banner.Message>{errorMessage}</Banner.Message>
        </Banner>
      )}
      <div className="grid gap-6 lg:grid-cols-2 2xl:grid-cols-3" data-testid="profile-page">
        <AccountOwnerInfoForm
          session={session}
          isGoogleUser={isGoogleUser}
          accountOwnerInfoFormState={accountOwnerInfoFormState}
          register={registerAccountOwnerInfoForm}
        />
        {!isSharedBuilderOnly && (
          <>
            <ProfileAvatar />
            <AccountInfoForm
              session={session}
              account={account}
              register={registerAccountInfoForm}
              formState={accountInfoFormState}
              control={control}
            />
          </>
        )}
      </div>
      <Button
        data-testid="account-save-changes-button"
        isLoading={isSaving}
        disabled={isSaving || !!accountInfoFormState.errors?.slug?.message || !isDirty}
        onClick={handleSubmitAccountOwnerInfo(() => {
          if (isSharedBuilderOnly) {
            handleSaveChanges();
            return;
          }
          void handleSubmitAccountInfo(() => handleSaveChanges())();
        })}
        className="mt-6 max-w-40"
      >
        {t('actions.save_changes')}
      </Button>
      <Dialog open={showPasswordDialog} onOpenChange={handlePasswordDialogClose}>
        <ConfirmAccountInfoChangeModal
          register={registerAccountOwnerInfoForm('password')}
          accountOwnerInfoFormState={accountOwnerInfoFormState}
          isGoogleUser={isGoogleUser}
          handleSubmit={handleSubmit}
          showPasswordDialog={showPasswordDialog}
          getAccountInfoFormValues={getAccountInfoFormValues}
          getAccountOwnerInfoFormValues={getAccountOwnerInfoFormValues}
        />
      </Dialog>
    </>
  );
}
