const orderedLocales = [
  {
    code: 'en'
  },
  {
    code: 'es'
  }
];

export const SUPPORTED_LOCALES = orderedLocales.map(({ code }) => code);
export const DEFAULT_LOCALE = 'en';
export const MAX_APP_NAME_AND_SLUG_CHARS = 128;
export const TRIAL_LENGTH_DAYS = 14;

export const LOCAL_STORAGE_KEYS = {
  isNewUser: 'isNewUser',
  gettingStartedDismissed: 'gettingStartedDismissed',
  createNewAppDismissed: 'createNewAppDismissed',
  anonymousFlagsId: 'anonymousFlagsId'
};
export const DISMISS_BANNER_SESSION_KEY = 'dismissedBanners';

export const RESTRICTION_ID = {
  frozen: 'banner-frozen',
  suspended: 'banner-suspended',
  suspensionWarning: 'banner-suspension-warning',
  appCreationError: 'banner-app-creation-error',
  reachedAppLimit: 'banner-reached-app-limit',
  trialExpired: 'banner-trial-expired',
  startTrial: 'banner-start-trial',
  trialExtended: 'banner-trial-extended'
};

export const IS_SMALL_SCREEN = window.innerWidth <= 768;

export const KNOWLEDGE_BASE_URL = 'https://learn.knack.com/article';
export const KNOWLEDGE_BASE_URL_NEXT_GEN = 'https://docs.knack.com/';
export const QUICK_START_VIDEO_URL = 'https://learn.knack.com/article/quick-start-videos';
export const EXPERT_DIRECTORY_URL = 'https://www.knack.com/experts/';
export const COMMUNITY_URL = 'https://forums.knack.com/';
export const VIDEOS_URL = 'https://www.youtube.com/@knackhq/videos';
export const MARKETING_PAGE_URL = 'https://www.knack.com';

export const STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES = {
  test: {
    starter: 'KNACKSTARTER',
    pro: 'KNACKPRO'
  },
  // change these two values for the actual ones once they are defined
  prod: {
    starter: 'STARTER3MONTH',
    pro: 'PRO3MONTH'
  },
  amount: {
    starter: 19,
    pro: 49
  }
};

export const STARTER_PRO_DISCOUNT_CAMPAIGN_COUPON_ID = {
  test: {
    starter: 'STARTERDISCOUNT',
    pro: 'PRODISCOUNT'
  },
  prod: {
    starter: 'STARTERPROMO',
    pro: 'PROPROMO'
  }
};

export enum PlanLevels {
  Starter = 1,
  Pro = 2,
  Corporate = 3,
  Plus1 = 4,
  Plus2 = 5,
  Plus3OrCustom = 6
}
