export const BUILDER_V3_IMPORT_PATH = 'schema/list/objects/add/import';
export const BUILDER_NEXT_IMPORT_PATH = 'tables/add';
export enum AppCreateType {
  Import = 'import',
  Scratch = 'scratch',
  Sample = 'template',
  Ai = 'ai',
  Auto = 'auto'
}

export enum WelcomePath {
  Ai = 'ai',
  Template = 'template'
}

export const DEFAULT_SAMPLES = [
  'quotes-and-invoices',
  'customer-portal-template',
  'custom-crm',
  'inventory-manager',
  'real-estate-listings'
];

export const SAMPLE_APPS_PRIORITIZED_DISPLAY_ORDER = [
  'business-directory',
  'custom-crm',
  'customer-portal-template',
  'real-estate-listings',
  'volunteer-management',
  'inventory-manager',
  'project-management',
  'multi-step-application',
  'property-management',
  'purchase-orders',
  'contact-directory'
];

export const TOP_SAMPLE_APPS = [
  'customer-portal-template',
  'custom-crm',
  'inventory-manager',
  'project-management',
  'contact-directory'
];

export const APPS_SKELETON_NUMBER_OF_ROWS = 13;
export const INDUSTRIES = [
  'financial_services',
  'marketing',
  'property_management',
  'software',
  'professional_services',
  'education',
  'government',
  'manufacturing',
  'retail',
  'healthcare',
  'non_profit',
  'construction'
];

export const SETUP_WIZARD_FILTERS = [
  'portals',
  'workflow',
  'directories',
  'e-commerce',
  'equations',
  'document-management',
  'project-management',
  'healthcare',
  'scheduling',
  'property-management',
  'non-profits',
  'legal',
  'construction',
  'retail',
  'software',
  'manufacturing',
  'hr',
  'education',
  'governments',
  'finance'
];

export const SETUP_WIZARD_INDUSTRIES_USE_CASES = {
  industries: [
    'construction',
    'education',
    'finance',
    'governments',
    'healthcare',
    'hr',
    'software',
    'legal',
    'property-management',
    'manufacturing',
    'non-profits',
    'retail',
    'professional-services',
    'other'
  ],
  useCases: [
    'portals',
    'online-database',
    'workflow',
    'directories',
    'document-management',
    'e-commerce',
    'equations',
    'forms-and-logging',
    'project-management',
    'reporting',
    'scheduling',
    'other'
  ]
};

export const USE_CASES_FOR_CONCRETE_TEMPLATE = [
  {
    key: 'task_project_tracker',
    appSlug: 'project-management-2023-edition-template'
  },
  {
    key: 'work_order_assignments',
    appSlug: 'work-order-and-shift-scheduling-app'
  },
  {
    key: 'client_crm_portal',
    appSlug: 'customer-portal-2023-edition-template'
  },
  {
    key: 'document_library',
    appSlug: 'document-management'
  },
  {
    key: 'resource_scheduler',
    appSlug: 'production-scheduling-template'
  },
  {
    key: 'inventory_manager',
    appSlug: 'inventory-manager'
  },
  {
    key: 'purchase_order_manager',
    appSlug: 'purchase-orders'
  },
  {
    key: 'equipment_supply_tracker',
    appSlug: 'medical-inventory-manager'
  },
  {
    key: 'employee_time_tracker',
    appSlug: 'payroll-and-timecard-manager'
  },
  {
    key: 'healthcare_practice_manager_emr',
    appSlug: 'practice-management-system'
  },
  {
    key: 'volunteer_member_hub',
    appSlug: 'volunteer-management'
  },
  {
    key: 'helpdesk_ticket_manager',
    appSlug: 'it-helpdesk-template'
  },
  {
    key: 'other',
    appSlug: null
  }
];
export const BASE_IMAGE_URL = '//cdn.knack.com/assets/setup-wizard/images/v1.0.0/';

export const SETUP_WIZARD_STEPS = ['industries', 'templates'];

/**
 * The list of templates that are displayed in the template gallery.
 * The formats are different for each template, this should be discussed with the design team.
 * Also, the slug it is not the app_slug, but the last portion of the URL.
 */
export const TEMPLATES_IMG_FORMAT = [
  { urlSlug: 'parent-and-student-portal', format: 'svg' },
  { urlSlug: 'sop-management', format: 'svg' },
  { urlSlug: 'job-portal', format: 'webp' },
  { urlSlug: 'volunteer-management', format: 'webp' },
  { urlSlug: 'mobile-workforce', format: 'webp' },
  { urlSlug: 'product-catalog', format: 'webp' },
  { urlSlug: 'government-public-service', format: 'jpg' },
  { urlSlug: 'loyalty-rewards-program', format: 'jpg' },
  { urlSlug: 'saas-landing-page', format: 'svg' },
  { urlSlug: 'business-directory', format: 'webp' },
  { urlSlug: 'document-management', format: 'jpg' },
  { urlSlug: 'customer-portal', format: 'webp' },
  { urlSlug: 'legal-case-management', format: 'jpg' },
  { urlSlug: 'multi-step-application', format: 'webp' },
  { urlSlug: 'sports-league', format: 'webp' },
  { urlSlug: 'rental-management', format: 'svg' },
  { urlSlug: 'job-listings', format: 'webp' },
  { urlSlug: 'online-donation-portal', format: 'webp' },
  { urlSlug: 'class-catalog', format: 'webp' },
  { urlSlug: 'customer-payment-portal', format: 'webp' },
  { urlSlug: 'coworking', format: 'webp' },
  { urlSlug: 'patient-scheduling', format: 'jpg' },
  { urlSlug: 'pto-tracker', format: 'svg' },
  { urlSlug: 'online-store', format: 'svg' },
  { urlSlug: 'medical-inventory', format: 'webp' },
  { urlSlug: 'municipal-payments-portal', format: 'webp' },
  { urlSlug: 'employee-hours', format: 'webp' },
  { urlSlug: 'event-calendar', format: 'webp' },
  { urlSlug: 'tutoring-company', format: 'webp' },
  { urlSlug: 'employee-training-safety-tracker', format: 'svg' },
  { urlSlug: 'school-applicant-tracker-template', format: 'webp' },
  { urlSlug: 'sales-territory-mapping-software', format: 'webp' },
  { urlSlug: 'image-gallery', format: 'webp' },
  { urlSlug: 'retail-inventory-manager-template', format: 'webp' },
  { urlSlug: 'marketing-campaign-planner', format: 'jpg' },
  { urlSlug: 'real-estate-listings', format: 'png' },
  { urlSlug: 'publication-management', format: 'svg' },
  { urlSlug: 'inventory-manager', format: 'webp' },
  { urlSlug: 'production-scheduler', format: 'svg' },
  { urlSlug: 'property-inspection', format: 'svg' },
  { urlSlug: 'punch-list', format: 'svg' },
  { urlSlug: 'construction-estimate-app', format: 'jpg' },
  { urlSlug: 'employee-reviews', format: 'webp' },
  { urlSlug: 'store-locator', format: 'webp' },
  { urlSlug: 'government-procurement-and-contracts', format: 'jpg' },
  { urlSlug: 'warehouse-manager', format: 'webp' },
  { urlSlug: 'test-laboratory-management', format: 'svg' },
  { urlSlug: 'school-incident-reporting', format: 'webp' },
  { urlSlug: 'supply-chain-management', format: 'svg' },
  { urlSlug: 'hoa-management', format: 'webp' },
  { urlSlug: 'tenant-portal', format: 'svg' },
  { urlSlug: 'school-management', format: 'svg' },
  { urlSlug: 'swot-analysis', format: 'svg' },
  { urlSlug: 'lost-and-found-app-template', format: 'webp' },
  { urlSlug: 'it-helpdesk', format: 'jpg' },
  { urlSlug: 'emr', format: 'jpg' },
  { urlSlug: 'open-house-management', format: 'svg' },
  { urlSlug: 'quotes-invoices', format: 'webp' },
  { urlSlug: 'job-dispatch', format: 'jpg' },
  { urlSlug: 'purchase-orders', format: 'webp' },
  { urlSlug: 'resource-planning', format: 'svg' },
  { urlSlug: 'project-management', format: 'webp' },
  { urlSlug: 'contact-directory', format: 'webp' },
  { urlSlug: 'maintenance-and-inspection', format: 'jpg' },
  { urlSlug: 'vendor-management', format: 'svg' },
  { urlSlug: 'for-freelancers', format: 'jpg' },
  { urlSlug: 'security-policy', format: 'svg' },
  { urlSlug: 'member-directory', format: 'webp' },
  { urlSlug: 'grant-management', format: 'jpg' },
  { urlSlug: 'investment-portfolio', format: 'jpg' },
  { urlSlug: 'learning-management-system', format: 'jpg' },
  { urlSlug: 'work-order', format: 'jpg' },
  { urlSlug: 'quality-control', format: 'svg' },
  { urlSlug: 'healthcare-lms', format: 'svg' },
  { urlSlug: 'construction-project-management', format: 'jpg' }
];

// This is temporarily here, in the future it should be moved to the Server sample app filters (industry/use-case)
export const TEMPLATES_BY_INDUSTRY = {
  construction: [
    'work-order-management',
    'quality-control-template',
    'employee-training-safety-template'
  ],
  education: ['parent-and-student-portal', 'class-catalog-and-enrollments', 'school-management'],
  finance: ['quotes-and-invoices', 'purchase-orders', 'investment-portfolio-template'],
  governments: [
    'government-procurement-and-contracts',
    'sop-management-template',
    'grants-management'
  ],
  healthcare: ['patient-scheduling', 'emr', 'practice-management-system'],
  hr: ['employee-training-safety-template', 'employee-reviews', 'employee-time-sheets'],
  software: ['project-management', 'customer-portal-template', 'it-helpdesk-template'],
  legal: ['legal-case-management', 'customer-portal-template', 'document-management'],
  'property-management': [
    'tenant-portal-template',
    'work-order-and-shift-scheduling-app',
    'rental-management-template'
  ],
  manufacturing: [
    'work-order-and-shift-scheduling-app',
    'inventory-manager',
    'production-scheduling-template'
  ],
  'non-profits': ['volunteer-management', 'online-donation-portal', 'inventory-manager'],
  retail: ['quotes-and-invoices', 'purchase-orders', 'loyalty-program-template'],
  'professional-services': [
    'multi-company-platform-template',
    'quotes-and-invoices',
    'project-management'
  ],
  other: ['contact-directory', 'customer-portal-template', 'quotes-and-invoices']
} as const;
