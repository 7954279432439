import axios, { type AxiosError } from 'axios';
import LogRocket from 'logrocket';

import { Route } from '@/enums/routing';
import { nonProtectedPaths } from '@/Router';
import { isFlagEnabled } from './flagsmith';

export const setDefaultAxiosUrlBase = (urlBase: string) => {
  axios.defaults.baseURL = urlBase;
};

export function setAxiosInterceptors() {
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const isUnauthorized = error.response?.status === 401;

      // In order to test Google SSO locally, we use localhost:5173 which returns 401 for requests using `withCredentials`, but we don't want to be redirected to the sign-in page
      const isLocalhost = window.location.hostname === 'localhost';

      if (isUnauthorized && !isLocalhost) {
        const pathSegments = window.location.pathname.split('/');
        const pathIsProtected = !nonProtectedPaths.includes(pathSegments[1]);

        if (pathIsProtected) {
          window.location.href = `/${Route.SignIn}`;
        }
      }

      LogRocket.captureException(error);

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        LogRocket.log('Response Data', error.response.data);
        LogRocket.log('Response Status', error.response.status);
        LogRocket.log('Response Headers', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest in the browser
        LogRocket.log('Request', error.request);
      } else {
        // Something happened in setting up the request that triggered the Axios error
        LogRocket.log('Axios Error Message', error.message);
      }

      return Promise.reject(error);
    }
  );
}

// The builder version header is included in the requests when creating new next-gen applications
export function getAppCreationRequestHeaders() {
  if (isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access')) {
    return {
      ...axios.defaults.headers.common,
      'x-knack-builder-version': 'next'
    };
  }

  return axios.defaults.headers.common;
}

/**
 * Return the loader api url
 * This URL is used when we want to get data from the api from mixed regions,
 * loader.knack.com has access to all different region DB.
 * Ex: Get app list, get shared apps, get plan usage.
 * This url will also be used if we are in us-govcloud or private environments
 */
export const getLoaderApiUrl = () => {
  // Remove first subdomain and change it to loader.
  // api.product.knack.com -> loader.product.knack.com
  // api.knack.com -> loader.knack.com
  const url = new URL(import.meta.env.PUBLIC_API_URL);
  // If it's localhost return the url directly
  if (url.hostname === 'localhost') {
    return import.meta.env.PUBLIC_API_URL;
  }

  const urlParts = url.host.split('.');
  const domain = urlParts.slice(0).slice(1).join('.');
  return `${url.protocol}//loader.${domain}`;
};
