import { useTranslation } from 'react-i18next';
import { HiMiniSquaresPlus as CustomDataIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { cn } from '@/utils/tailwind';
import { BASE_IMAGE_URL } from '@/pages/apps/create-app/constants';
import { translationKeyPrefixVariantTwo } from '@/pages/apps/create-app/setup-wizard-variations/constants';
import {
  getCardImage,
  getTitleFromFilter,
  getUrlForAICard
} from '@/pages/apps/create-app/setup-wizard-variations/helper';

interface TemplateCardProps {
  type: 'template' | 'customize' | 'import' | 'more';
  template?: SampleApp;
  selectedTemplateId?: string;
  aiCardFlagValue?: 'personalized' | 'generate_ai';
  isDarkMode?: boolean;
  onClick: () => void;
  selectedIndustry?: string;
  selectedUseCase?: string;
}

export function TemplateCard({
  type,
  template,
  selectedTemplateId,
  aiCardFlagValue,
  isDarkMode,
  onClick,
  selectedIndustry,
  selectedUseCase
}: TemplateCardProps) {
  const [t] = useTranslation();

  const title = (() => {
    switch (type) {
      case 'template':
        return template?.title;
      case 'customize':
        return t(`${translationKeyPrefixVariantTwo}.step_2.customize_${aiCardFlagValue}`, {
          portal:
            selectedUseCase && selectedUseCase !== 'other'
              ? getTitleFromFilter!(t, selectedUseCase)
              : ''
        });
      case 'import':
        return t(`${translationKeyPrefixVariantTwo}.step_2.start_with_a_table`);
      case 'more':
        return selectedIndustry === 'other'
          ? t(`${translationKeyPrefixVariantTwo}.step_2.explore_all_templates`)
          : t(`${translationKeyPrefixVariantTwo}.step_2.explore_more_templates`);
      default:
        return '';
    }
  })();

  const description = (() => {
    switch (type) {
      case 'template':
        return template?.description;
      case 'customize':
        return t(`${translationKeyPrefixVariantTwo}.step_2.customize_description`);
      case 'import':
        return t(`${translationKeyPrefixVariantTwo}.step_2.start_with_a_table_description`);
      default:
        return '';
    }
  })();

  const renderContent = () => {
    switch (type) {
      case 'template': {
        let imageContent;

        if (template?.link) {
          const cardImage = getCardImage(template.link);
          if (cardImage) {
            if (cardImage.format === 'webp') {
              imageContent = (
                <picture className="h-48 w-full rounded-t-lg object-cover">
                  <source
                    srcSet={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.webp`}
                    type="image/webp"
                  />
                  <img
                    src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.jpg`}
                    alt={template?.title}
                    className="h-48 w-full rounded-t-lg object-cover"
                  />
                </picture>
              );
            } else {
              imageContent = (
                <img
                  src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.${cardImage.format}`}
                  alt={template?.title}
                  className="h-48 w-full rounded-t-lg object-cover"
                />
              );
            }
          }
        }

        if (!imageContent) {
          imageContent = (
            <img
              src={`${BASE_IMAGE_URL}template-apps-cards-images/warehouse-manager.jpg`}
              alt={title}
              className="h-48 w-full rounded-t-lg object-cover"
            />
          );
        }

        return (
          <>
            {imageContent}
            <div className="flex w-full items-center justify-between">
              <h3 className="mt-4 font-semibold text-emphasis">{title}</h3>
            </div>
            <p className="mt-2 text-xs text-subtle">{description}</p>
          </>
        );
      }

      case 'customize': {
        const aiImageUrl = getUrlForAICard(selectedUseCase || '', isDarkMode || false);
        return (
          <>
            <img src={aiImageUrl} alt={title} className="h-48 w-full rounded-t-lg object-cover" />
            <h3 className="mt-4 font-semibold text-emphasis">{title}</h3>
            <p className="mt-2 text-xs text-subtle">{description}</p>
          </>
        );
      }

      case 'import':
        return (
          <>
            <img
              src={`${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-start-table.svg`}
              alt={title}
              className="h-48 w-full rounded-t-lg object-cover"
            />
            <div className="flex w-full items-center justify-between">
              <h3 className="mt-4 font-semibold text-emphasis">{title}</h3>
            </div>
            <p className="mt-2 text-xs text-subtle">{description}</p>
          </>
        );

      case 'more':
        return (
          <div className="flex h-full flex-col items-center justify-center text-center">
            <CustomDataIcon
              className="text-gradient-1 mb-6 fill-[url(#svg-gradient-2)]"
              size={60}
            />
            <Button intent="link" size="lg" asChild onClick={onClick}>
              <span>{title}</span>
            </Button>
          </div>
        );

      default:
        return null;
    }
  };

  const cardClasses = cn(
    'flex flex-col items-start border-transparent p-4 transition-all sm:p-2 sm:pb-4',
    type === 'template' && selectedTemplateId === template?.id
      ? 'border-brand-default border-2'
      : 'border-transparent',
    type === 'template' && selectedTemplateId && selectedTemplateId !== template?.id
      ? 'opacity-50'
      : '',
    type === 'more' && 'flex flex-col items-center justify-center'
  );

  return (
    <Card data-testid={`${type}-card`} className={cardClasses} onClick={onClick}>
      {renderContent()}
    </Card>
  );
}
