import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@knack/asterisk-react';

import { type ProductPlan } from '@/types/account';
import { PLAN_FREQUENCY, PLAN_TYPE } from '@/types/plans';

interface SelectPlanButtonProps {
  knackPlan: ProductPlan;
  currentPlan: ProductPlan;
  onPlanSelect: (plan: ProductPlan) => void;
  isDowngradeAllowed: boolean;
}

export function SelectPlanButton({
  knackPlan,
  currentPlan,
  isDowngradeAllowed,
  onPlanSelect
}: SelectPlanButtonProps) {
  const [t] = useTranslation();
  const commonProps: Partial<React.ComponentProps<typeof Button>> = {
    className: 'mt-auto',
    intent: undefined,
    onClick: () => onPlanSelect(knackPlan)
  } as const;

  if (currentPlan.id === 'trial') {
    return (
      <Button {...commonProps} data-testid="select-plan-button">
        {t('actions.upgrade')}
      </Button>
    );
  }

  // Same plan
  // Note: The IDs for the plans with the new pricing (e.g.: 'starter_241001') are different than the IDs for the legacy plans (e.g.: 'starter_230901'),
  // so this is a way to check if the two plans are the "same" (e.g.: 'starter_230901' is equivalent to 'starter_241001')
  if (currentPlan.id.replace('230901', '241001') === knackPlan.id) {
    return (
      <Button {...commonProps} disabled intent="secondary" data-testid="select-plan-button">
        {t('components.billing.overview.current_plan')}
      </Button>
    );
  }

  // Only allow changing custom or agency plans by contacting support
  if (currentPlan.type === 'custom' || currentPlan.id.includes(PLAN_TYPE.Agency)) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button className="mt-auto" data-testid="contact-support-button" intent="secondary">
            <a href="mailto:support@knack.com">{t('components.billing.plans.contact_support')}</a>
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>{t('components.billing.plans.contact_support_tooltip')}</Tooltip.Content>
      </Tooltip>
    );
  }

  // Same plan level, but current plan is monthly and we're showing yearly
  if (
    knackPlan.level === currentPlan.level &&
    knackPlan.frequency === PLAN_FREQUENCY.Yearly &&
    !currentPlan.frequency
  ) {
    return (
      <Button data-testid="select-plan-button" {...commonProps}>
        {t('components.billing.plans.switch_to_yearly')}
      </Button>
    );
  }

  // Plan is higher level so upgrade is allowed
  if (knackPlan.level > currentPlan.level) {
    return (
      <Button data-testid="select-plan-button" {...commonProps}>
        {t('actions.upgrade')}
      </Button>
    );
  }

  // Plan is lower level so downgrade is allowed
  if (knackPlan.level <= currentPlan.level) {
    // Disable downgrade button if the user is on an Enterprise plan or if the user is on a HIPAA plan
    if (!isDowngradeAllowed) {
      return (
        <Button data-testid="select-plan-button" {...commonProps} disabled intent="secondary">
          {t('actions.downgrade')}
        </Button>
      );
    }
    if (currentPlan.level >= 4) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Button className="mt-auto" data-testid="contact-support-button" intent="secondary">
              <a href="mailto:support@knack.com">{t('components.billing.plans.contact_support')}</a>
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>{t('components.billing.plans.contact_support_tooltip')}</Tooltip.Content>
        </Tooltip>
      );
    }

    return (
      <Button data-testid="select-plan-button" {...commonProps} intent="secondary">
        {t('actions.downgrade')}
      </Button>
    );
  }

  return (
    <Button data-testid="select-plan-button" {...commonProps}>
      {t('actions.select')}
    </Button>
  );
}
