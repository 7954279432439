import { useEffect, useState, type JSX } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { Route } from '@/enums';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { useIsSharedBuilderOnly } from '@/hooks/useSession';
import { useSetupWizardPermissions } from '@/hooks/useSetupWizardPermissions';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import { AccountDeleted } from '@/pages/account-deleted';
import { AppsPage } from '@/pages/apps/AppsPage';
import { InstallApp } from '@/pages/apps/InstallApp';
import { EmailNotFound } from '@/pages/email-not-found';
import { ForgotPassword } from '@/pages/forgot-password';
import { NotFound } from '@/pages/not-found';
import { ResetPassword } from '@/pages/reset-password';
import { GettingStarted } from '@/pages/resources/getting-started';
import { SetPassword } from '@/pages/set-password';
import { Account } from '@/pages/settings/account';
import { Billing } from '@/pages/settings/billing';
import { Security } from '@/pages/settings/security';
import { SignIn } from '@/pages/sign-in';
import { SignUp } from '@/pages/sign-up';
import { SetupWizard } from './pages/apps/create-app/SetupWizard';

export const nonProtectedPaths: string[] = [
  Route.SignIn,
  Route.SignUp,
  Route.ForgotPassword,
  Route.ResetPassword,
  Route.SetPassword
];

type ProtectedRouteProps = {
  isForbidden: boolean;
  redirectPath: string;
  children?: JSX.Element;
};

function ProtectedRoute({ isForbidden, redirectPath, children }: ProtectedRouteProps) {
  const { search } = useLocation();

  if (isForbidden) {
    return <Navigate to={{ pathname: `${redirectPath}`, search }} replace />;
  }
  return children || <Outlet />;
}

function AccountQueryGuard({
  shouldGuardOnFirstLoad,
  children
}: {
  shouldGuardOnFirstLoad?: boolean;
  children?: JSX.Element;
}) {
  const { isFetching: isAccountFetching, isLoading: isAccountLoading } = useAccountQuery();

  if (shouldGuardOnFirstLoad) {
    return isAccountLoading ? <FullPageSpinner /> : children;
  }
  return isAccountFetching ? <FullPageSpinner /> : children;
}

export function Router() {
  const { search, pathname } = useLocation();
  const [isNewUser] = useLocalStorage(LOCAL_STORAGE_KEYS.isNewUser, true);
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const [selectedPath, setSelectedPath] = useState('');
  const params = new URLSearchParams(search);
  const { data: session } = useSessionQuery();
  const { data: account } = useAccountQuery();
  const isAuthenticated = !!session?.user.id;
  const { isSetupWizardEnabled } = usePostSignUpContext();
  const { isRedirectRequired: isSetupWizardRedirectRequired, isForbidden: isSetupWizardForbidden } =
    useSetupWizardPermissions();

  // Check for the presence of the `isNewUser` flag in localStorage, and if the user is coming from Knack's marketing website.
  // The goal is to reduce user friction when coming from a sample app install link by ensuring that new users go to /sign-up while current users go to /sign-in
  const shouldShowSignupPage =
    (document.referrer.includes('www.knack.com') ||
      document.referrer.includes('pages.knack.com')) &&
    isNewUser;

  useEffect(() => {
    if (isRudderStackEnabled()) {
      // Do not send the page event if the url has a token
      if (!pathname.includes('/reset-password/') && !pathname.includes('/set-password/')) {
        void rudderStackAnalytics.page();
      }
    }
  }, [pathname]);

  // Should we send analytics events when the user is authenticated to Hubspot now?

  useEffect(() => {
    if (!isAuthenticated) {
      const pathSegments = pathname.split('/');
      const pathIsProtected = !nonProtectedPaths.includes(pathSegments[1]);

      if (pathIsProtected) {
        setSelectedPath(pathname);
      }
    }

    // Don't add location.pathname to dependencies, otherwise it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return useRoutes([
    {
      element: (
        <ProtectedRoute
          isForbidden={!isAuthenticated}
          redirectPath={
            shouldShowSignupPage
              ? `/${Route.SignUp}${params.has('app_id') ? `?appId=${params.get('app_id')}` : ''}`
              : `/${Route.SignIn}`
          }
        />
      ),
      children: [
        {
          path: Route.Root,
          element: <Navigate to={{ pathname: Route.Apps, search }} replace />
        },
        {
          path: Route.Apps,
          element: (
            <ProtectedRoute
              isForbidden={
                isSharedBuilderOnly || isSetupWizardEnabled || isSetupWizardRedirectRequired
              }
              redirectPath={
                isSharedBuilderOnly ? `/${Route.Apps}/${Route.SharedApps}` : `/${Route.SetupWizard}`
              }
            >
              <AppsPage />
            </ProtectedRoute>
          )
        },
        {
          path: Route.SetupWizard,
          element: (
            <AccountQueryGuard shouldGuardOnFirstLoad>
              <ProtectedRoute isForbidden={isSetupWizardForbidden} redirectPath={`/${Route.Apps}`}>
                <SetupWizard />
              </ProtectedRoute>
            </AccountQueryGuard>
          )
        },
        {
          path: `${Route.Apps}/${Route.SharedApps}`,
          element: <AppsPage activeTab={Route.SharedApps} />
        },
        {
          path: `${Route.Apps}/${Route.Install}/:app_id`,
          element: (
            <ProtectedRoute isForbidden={isSharedBuilderOnly} redirectPath={`/${Route.Apps}`}>
              <InstallApp />
            </ProtectedRoute>
          )
        },
        {
          path: Route.Settings,
          children: [
            {
              path: Route.Account,
              element: (
                <AccountQueryGuard>
                  <Account activeTab={Route.Account} />
                </AccountQueryGuard>
              )
            },
            {
              path: `${Route.Account}/${Route.Management}`,
              element: (
                <ProtectedRoute
                  isForbidden={isSharedBuilderOnly}
                  redirectPath={`/${Route.Settings}/${Route.Account}`}
                >
                  <Account activeTab={`${Route.Account}/${Route.Management}`} />
                </ProtectedRoute>
              )
            },
            {
              path: Route.Security,
              element: <Security />
            },
            {
              path: Route.Billing,
              element: (
                <ProtectedRoute isForbidden={isSharedBuilderOnly} redirectPath={`/${Route.Apps}`}>
                  <Billing />
                </ProtectedRoute>
              )
            },
            {
              path: `${Route.Billing}/${Route.Plans}`,
              element: (
                <ProtectedRoute
                  isForbidden={isSharedBuilderOnly || !!account?.isHipaa}
                  redirectPath={`/${Route.Apps}`}
                >
                  <Billing activeTab={Route.Plans} />
                </ProtectedRoute>
              )
            },
            {
              path: `${Route.Billing}/${Route.AddOns}`,
              element: (
                <ProtectedRoute
                  isForbidden={
                    isSharedBuilderOnly ||
                    !!account?.isHipaa ||
                    !!account?.isTrial ||
                    !!account?.isInternalAccount ||
                    !!account?.product_plan?.id.includes('starter')
                  }
                  redirectPath={`/${Route.Apps}`}
                >
                  <Billing activeTab={Route.AddOns} />
                </ProtectedRoute>
              )
            },
            {
              path: `${Route.Billing}/${Route.Payment}`,
              element: (
                <ProtectedRoute
                  isForbidden={isSharedBuilderOnly || !!account?.isTrial}
                  redirectPath={`/${Route.Apps}`}
                >
                  <Billing activeTab={Route.Payment} />
                </ProtectedRoute>
              )
            },
            {
              path: `${Route.Billing}/${Route.Invoices}`,
              element: (
                <ProtectedRoute
                  isForbidden={isSharedBuilderOnly || !!account?.isTrial}
                  redirectPath={`/${Route.Apps}`}
                >
                  <Billing activeTab={Route.Invoices} />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: `${Route.Resources}/${Route.GettingStarted}`,
          element: <GettingStarted />
        },
        {
          path: Route.AccountDeleted,
          element: <AccountDeleted />
        }
      ]
    },
    {
      element: (
        <ProtectedRoute
          isForbidden={isAuthenticated}
          redirectPath={selectedPath || `/${Route.Apps}`}
        />
      ),
      children: [
        {
          path: Route.SignIn,
          element: <SignIn />
        },
        {
          path: Route.SignUp,
          element: <SignUp />
        },
        {
          path: Route.ForgotPassword,
          element: <ForgotPassword />
        },
        {
          path: `${Route.ResetPassword}/:token`,
          element: <ResetPassword />
        },
        {
          path: `${Route.SetPassword}/:token`,
          element: params.has('email') ? <SetPassword /> : <EmailNotFound />
        }
      ]
    },
    {
      path: Route.CatchAll,
      element: <NotFound />
    }
  ]);
}
