import { useTranslation } from 'react-i18next';
import { HiArrowRight as ArrowRight, HiMiniSquaresPlus as CustomDataIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';

import { BASE_IMAGE_URL } from '@/pages/apps/create-app/constants';
import {
  getCardImage,
  getUrlForAICard
} from '@/pages/apps/create-app/setup-wizard-variations/helper';
import {
  getActionTextByType,
  getCardClasses,
  getDescriptionByType,
  getTitleByType
} from './helper';

interface TemplateCardTemplateProps {
  title: string;
  description: string;
  actionText: string;
  template?: {
    link?: string;
  };
  onTemplateCardClick: (template: any) => void;
}

interface TemplateCardCustomizeProps {
  title: string;
  description: string;
  actionText: string;
  isDarkMode?: boolean;
  selectedUseCase?: string;
}

interface TemplateCardImportProps {
  title: string;
  description: string;
  actionText: string;
  isDarkMode?: boolean;
}

interface TemplateCardMoreProps {
  title: string;
  onClick: () => void;
}

export interface TemplateCardVerticalUIProps {
  type: 'template' | 'customize' | 'import' | 'more';
  template?: {
    link?: string;
  };
  isTemplateSelected?: boolean;
  aiCardFlagValue?: 'personalized' | 'generate_ai';
  isDarkMode?: boolean;
  onClick: () => void;
  onTemplateCardClick?: (template: any) => void;
  selectedIndustry?: string;
  selectedUseCase?: string;
}

function TemplateCardTemplate({
  title,
  description,
  actionText,
  template,
  onTemplateCardClick
}: TemplateCardTemplateProps) {
  const cardImage = getCardImage(template?.link ?? 'warehouse-manager');
  const imageContent =
    cardImage?.format === 'webp' ? (
      <picture className="size-full object-cover">
        <source
          srcSet={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.webp`}
          type="image/webp"
        />
        <img
          src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.jpg`}
          alt={title}
          className="space-12 mt-1 size-full min-h-24 rounded-lg object-cover"
        />
      </picture>
    ) : (
      <img
        src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.${cardImage.format}`}
        alt={title}
        className="space-12 mt-1 size-full min-h-24 rounded-lg object-cover"
      />
    );

  return (
    <div className="flex size-full">
      <div className="size-full w-1/5">{imageContent}</div>
      <div className="flex w-4/5 flex-col justify-start p-4">
        <h3 data-testid="card-title" className="truncate text-md font-semibold text-emphasis">
          {title}
        </h3>
        <p data-testid="card-description" className="mb-2 truncate text-sm text-subtle">
          {description}
        </p>
        <span
          className="-mb-4 flex items-center truncate text-md text-brand"
          role="button"
          tabIndex={0}
          onClick={() => onTemplateCardClick(template)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              onTemplateCardClick(template);
            }
          }}
        >
          {actionText}
          <ArrowRight className="ml-2 text-brand" />
        </span>
      </div>
    </div>
  );
}

function TemplateCardCustomize({
  title,
  description,
  actionText,
  isDarkMode,
  selectedUseCase
}: TemplateCardCustomizeProps) {
  const aiImageUrl = getUrlForAICard(selectedUseCase || '', isDarkMode || false);

  return (
    <div className="flex size-full">
      <div className="size-full w-1/5 rounded-sm">
        <img
          src={aiImageUrl}
          alt={title}
          className="space-12 mt-1 size-full min-h-24 rounded-lg object-cover"
        />
      </div>
      <div className="flex w-4/5 flex-col justify-center p-4">
        <h3 className="truncate text-md font-semibold text-emphasis">{title}</h3>
        <p className="mb-2 truncate text-sm text-subtle">{description}</p>
        <p className="flex items-center truncate text-md text-brand">
          {actionText}
          <ArrowRight className="ml-2 text-brand" />
        </p>
      </div>
    </div>
  );
}

function TemplateCardImport({
  title,
  description,
  actionText,
  isDarkMode
}: TemplateCardImportProps) {
  return (
    <div className="flex size-full">
      <div className="size-full w-1/5 rounded-sm">
        <img
          src={`${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-start-table.svg`}
          alt={title}
          className="space-12 mt-1 size-full min-h-24 rounded-lg object-cover"
        />
      </div>
      <div className="flex w-4/5 flex-col justify-start p-4">
        <h3 className="truncate text-md font-semibold text-emphasis">{title}</h3>
        <p className="mb-2 truncate text-sm text-subtle">{description}</p>
        <span className="-mb-4 flex items-center truncate text-md text-brand">
          {actionText}
          <ArrowRight className="ml-2 text-brand" />
        </span>
      </div>
    </div>
  );
}

function TemplateCardMore({ title, onClick }: TemplateCardMoreProps) {
  return (
    <div className="flex size-full items-center justify-center">
      <CustomDataIcon className="text-gradient-1 mb-2 fill-[url(#svg-gradient-2)]" size={40} />
      <Button intent="link" size="lg" asChild onClick={onClick}>
        <span>{title}</span>
      </Button>
    </div>
  );
}

export function TemplateCardVerticalUI(props: TemplateCardVerticalUIProps) {
  const {
    type,
    onClick,
    template,
    isTemplateSelected,
    aiCardFlagValue,
    isDarkMode,
    selectedUseCase,
    onTemplateCardClick = () => {}
  } = props;

  const { t } = useTranslation();
  const commonProps = {
    title: getTitleByType(type, template, {
      t,
      selectedUseCase,
      aiCardFlagValue
    }),
    description: getDescriptionByType(type, template, { t }),
    actionText: getActionTextByType(type, { t }),
    onClick,
    isDarkMode
  };

  const renderCard = () => {
    switch (type) {
      case 'template':
        return (
          <TemplateCardTemplate
            {...commonProps}
            template={template}
            onTemplateCardClick={onTemplateCardClick}
          />
        );
      case 'customize':
        return <TemplateCardCustomize {...commonProps} selectedUseCase={selectedUseCase} />;
      case 'import':
        return <TemplateCardImport {...commonProps} />;
      case 'more':
        return <TemplateCardMore {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <Card
      data-testid={`${type}-card`}
      className={getCardClasses(type, isTemplateSelected)}
      onClick={onClick}
    >
      {renderCard()}
    </Card>
  );
}
