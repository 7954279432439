import { useTranslation } from 'react-i18next';
import { Banner } from '@knack/asterisk-react';

export function AppCreationErrorBanner({ appCreationError }: { appCreationError: string }) {
  const [t] = useTranslation();
  return (
    <Banner
      data-testid="app-creation-error-banner"
      intent="destructive"
      title={t('components.create_app.create_app_error_title')}
      closeMode="text"
    >
      <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
    </Banner>
  );
}
