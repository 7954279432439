import { update as intercomUpdate } from '@intercom/messenger-js-sdk';
import { type ApiObject } from '@rudderstack/analytics-js/*';

import { type SampleApp } from '@/types/apps';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import {
  BASE_IMAGE_URL,
  TEMPLATES_BY_INDUSTRY,
  TEMPLATES_IMG_FORMAT,
  USE_CASES_FOR_CONCRETE_TEMPLATE
} from '@/pages/apps/create-app/constants';

export const renameFilterBeforeCheckTemplate = (key: string): string => {
  const keyMappings = {
    directories: 'directory',
    equations: 'calculator',
    portals: 'portal',
    payments: 'payment',
    'e-commerce': 'payment',
    education: 'schools'
  } as const;

  return keyMappings[key] || key;
};

export const getUrlForAICard = (selectedUseCase: string, isDarkMode: boolean) => {
  const useCaseKey = renameFilterBeforeCheckTemplate(selectedUseCase);
  if (useCaseKey === 'online-database') {
    return `${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-other.svg`;
  }
  return `${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-${useCaseKey}.svg`;
};

// Gets the 3 best scored templates for the selected industry, provided by Product team
export const getTemplateAppsFilteredAndSorted = (
  selectedIndustry: string,
  selectedUseCase?: string,
  sampleApps: SampleApp[] = [],
  shouldShowAllTemplates: boolean = false
): SampleApp[] => {
  if (!selectedIndustry || sampleApps.length === 0) return [];

  const sortByScore = (a: SampleApp, b: SampleApp) => (b.score ?? 0) - (a.score ?? 0);

  const getAllTemplatesForIndustry = () => {
    if (selectedIndustry === 'other') {
      return [...sampleApps].sort(sortByScore);
    }

    const industryCategory = renameFilterBeforeCheckTemplate(selectedIndustry);
    return [...sampleApps]
      .filter((app) => app.categories?.includes(industryCategory))
      .sort(sortByScore);
  };

  const getTopTemplatesForIndustry = () => {
    const industryTemplates = TEMPLATES_BY_INDUSTRY[selectedIndustry];

    const templatesFiltered = industryTemplates
      ? sampleApps.filter((app) => industryTemplates.includes(app.slug))
      : [];

    // New scenario, in the Vertical Stack UI we need to add the app that matches the selected use case if
    // it was not added already when filtering by industry
    if (
      selectedUseCase &&
      USE_CASES_FOR_CONCRETE_TEMPLATE.some((useCase) => useCase.key === selectedUseCase)
    ) {
      if (selectedUseCase.toLowerCase() !== 'other') {
        if (!templatesFiltered.some((app) => app.slug === selectedUseCase)) {
          const useCaseTemplate = sampleApps.find(
            (app) =>
              app.slug ===
              USE_CASES_FOR_CONCRETE_TEMPLATE.find((t) => t.key === selectedUseCase)?.appSlug
          );
          if (useCaseTemplate && !templatesFiltered.includes(useCaseTemplate)) {
            templatesFiltered.unshift(useCaseTemplate);
            templatesFiltered.pop();
          }
        }
      }
    }
    return templatesFiltered;
  };

  return shouldShowAllTemplates ? getAllTemplatesForIndustry() : getTopTemplatesForIndustry();
};

// Given the link that is the full URL of the template, return name and format of the file
// In the public-cdn repo, as the slug located there is the urlSlug, not the appSlug
export const getCardImage = (link: string) => {
  const image = TEMPLATES_IMG_FORMAT.find((img) => link.includes(img.urlSlug));
  if (!image) {
    return { urlSlug: 'warehouse-manager', format: 'webp' };
  }
  return image;
};

interface AnalyticsDataInterface {
  app: string;
  groupId: string;
  builderVersion: string;
  industry?: string;
  use_case?: string;
  category?: string;
}

export interface AnalyticsIndustryUseCaseInterface {
  groupId: string;
  industry: string;
  intent?: string;
  useCase: string;
  other_details: string;
}
export function trackEventSampleAppCreated(
  event: string,
  selectedTemplateSlug: string,
  accountId: string,
  isBuilderV4Enabled: boolean,
  t: (key: string) => string,
  selectedIndustry?: string,
  selectedUseCase?: string,
  selectedCategory?: string
) {
  const analyticsData: AnalyticsDataInterface = {
    app: selectedTemplateSlug,
    groupId: accountId,
    builderVersion: isBuilderV4Enabled ? '4' : '3'
  };

  if (selectedIndustry) {
    analyticsData.industry = t(
      `components.onboarding.questionnaire.questions.categories.options.${selectedIndustry.replace(
        '-',
        '_'
      )}`
    );
  }

  if (selectedUseCase) {
    analyticsData.use_case = t(
      `components.onboarding.questionnaire.questions.categories.options.${selectedUseCase.replace(
        '-',
        '_'
      )}`
    );
  }

  if (selectedCategory) {
    analyticsData.category = t(
      `components.onboarding.questionnaire.questions.categories.options.${selectedCategory.replace(/-/g, '_')}`
    );
  }
  void rudderStackAnalytics.track(event, analyticsData as unknown as ApiObject);
}

export function trackEventImportAppCreated(groupId: string, isBuilderV4Enabled: boolean) {
  void rudderStackAnalytics.track('Setup Wizard: Start with a Table (Import) clicked', {
    groupId,
    builderVersion: isBuilderV4Enabled ? '4' : '3'
  });
}

export function trackEventAiClicked(groupId: string) {
  void rudderStackAnalytics.track('Setup Wizard: Customize My App/AI clicked', {
    groupId
  });
}

export function trackIndustryUseCaseEvent(body: AnalyticsIndustryUseCaseInterface) {
  void rudderStackAnalytics.track(
    'Setup Wizard: Industry and Use Case sent',
    body as unknown as ApiObject
  );
}

export function updateIntercomIndustryUseCase(industry: string, useCase: string, intent?: string) {
  // Update custom attributes in Intercom
  void intercomUpdate({
    industry,
    use_case: useCase,
    intent: intent ?? '' // only available in the vertical stack UI variant
  });
}

export const getTitleFromFilter = (t, key: string) =>
  t(`components.onboarding.questionnaire.questions.categories.options.${key.replace(/-/g, '_')}`);
