import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowSmallLeft as ArrowIcon } from 'react-icons/hi2';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@knack/asterisk-react';

import { Route } from '@/enums';
import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { getSampleWelcomePath } from '@/utils/samples';
import { PageError } from '@/components/errors/PageError';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';

export function InstallApp() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const session = useSession();
  const [errorMessage, setErrorMessage] = useState('');
  const { createAppFromSample } = useAppMutation();
  const {
    data: sampleApps,
    isLoading: isSampleAppsLoading,
    error: sampleAppsError
  } = useSampleAppsQuery();

  useEffect(() => {
    if (!isSampleAppsLoading) {
      const sampleApp = sampleApps?.find((app: SampleApp) => app.id === params.app_id);

      if (!sampleApp) {
        setErrorMessage(t('components.create_app.sample_app_not_found'));
      } else if (sampleAppsError) {
        setErrorMessage(t('components.create_app.error_loading_sample_apps'));
      } else if (params.app_id && session.account.slug) {
        const data = {
          appId: params.app_id,
          templateSlug: sampleApp.slug,
          appDescription: sampleApp.description
        };
        createAppFromSample.mutate(data, {
          onSuccess: async (response) => {
            if (shouldSendAnalytics(session.user.email)) {
              await rudderStackAnalytics.track('New App From Sample Created', {
                app: sampleApp.title,
                groupId: session.account.id
              });
            }
            window.location.href = `${isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access') ? import.meta.env.PUBLIC_BUILDER_NEXT_URL : import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
              response.app.slug
            }/${getSampleWelcomePath(sampleApp)}`;
          },
          onError: (error) => {
            const message = getErrorMessage(
              error,
              t('components.create_app.create_app_error_message')
            );
            setErrorMessage(message);
          }
        });
      }
    }
    // Don't add more dependencies to this hook, it will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.app_id,
    session.account.slug,
    session.account.id,
    session.user.email,
    isSampleAppsLoading,
    t
  ]);

  if (isSampleAppsLoading || createAppFromSample.isPending) {
    return <FullPageSpinner />;
  }

  return (
    <div className="full-page-wrapper" data-testid="marketplace-template-install">
      {errorMessage && (
        <PageError
          title={t('components.create_app.create_app_error_title')}
          message={errorMessage}
          button={
            <Button
              className="gap-1"
              onClick={() => {
                navigate({
                  pathname: `/${Route.Apps}`
                });
              }}
            >
              <ArrowIcon size={14} />
              {t('actions.back')}
            </Button>
          }
        />
      )}
    </div>
  );
}
