import { type MultiSelectOption } from '@knack/asterisk-react';

export const translationKeyPrefixVariantTwo = 'components.setup_wizard.version_1.9' as const;
export const translationKeyPrefixVerticalUI = 'components.setup_wizard.vertical_stack_ui' as const;

export const goalsList: MultiSelectOption[] = [
  {
    label: 'Store & Organize Data',
    key: 'store_organize_data',
    value: 'Store & Organize Data',
    isDisabled: false,
    description: 'Database, records, CSV files'
  },
  {
    label: 'Collect Data from my Users',
    key: 'collect_data',
    value: 'Collect Data from my Users',
    isDisabled: false,
    description: 'Forms, checklists'
  },
  {
    label: 'Visualize my Data',
    key: 'visualize_my_data',
    value: 'Visualize my Data',
    isDisabled: false,
    description: 'Reports, charts, dashboards'
  },
  {
    label: 'Create Automations & Integrations',
    key: 'create_automations_integrations',
    value: 'Create Automations & Integrations',
    isDisabled: false,
    description: 'Triggers, actions'
  },
  {
    label: 'Build a Digital Process',
    key: 'build_digital_process',
    value: 'Build a Digital Process',
    isDisabled: false,
    description: 'Approvals, projects, cases'
  },
  {
    label: 'Customize Access for Groups',
    key: 'customize_access',
    value: 'Customize Access for Groups',
    isDisabled: false,
    description: 'Portals, permissions'
  }
];
