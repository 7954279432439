import { cn } from '@/utils/tailwind';
import {
  goalsList,
  translationKeyPrefixVerticalUI
} from '@/pages/apps/create-app/setup-wizard-variations/constants';

export function getTitleByType(type: string, template, { t, selectedUseCase, aiCardFlagValue }) {
  switch (type) {
    case 'template':
      return template.title;
    case 'customize':
      return t(`${translationKeyPrefixVerticalUI}.step_2.customize_${aiCardFlagValue}`, {
        portal: selectedUseCase || ''
      });
    case 'import':
      return t(`${translationKeyPrefixVerticalUI}.step_2.start_with_a_table`);
    case 'more':
      return t(`${translationKeyPrefixVerticalUI}.step_2.explore_more`);
    default:
      return '';
  }
}

export function getDescriptionByType(type: string, template, { t }) {
  switch (type) {
    case 'template':
      return template.description;
    case 'customize':
      return t(`${translationKeyPrefixVerticalUI}.step_2.customize_description`);
    case 'import':
      return t(`${translationKeyPrefixVerticalUI}.step_2.start_with_a_table_description`);
    default:
      return '';
  }
}

export function getActionTextByType(type, { t }) {
  switch (type) {
    case 'template':
      return t(`${translationKeyPrefixVerticalUI}.step_2.instant_setup`);
    case 'customize':
      return t(`${translationKeyPrefixVerticalUI}.step_2.start_customizing`);
    case 'import':
      return t(`${translationKeyPrefixVerticalUI}.step_2.start_building`);
    default:
      return t(`${translationKeyPrefixVerticalUI}.step_2.instant_setup`);
  }
}

export function getCardClasses(type: string, isTemplateSelected?: boolean) {
  return cn(
    'flex items-center max-w-[600px] size-full border border-transparent transition-all p-0 sm:p-2',
    type === 'template' && isTemplateSelected && 'border-brand-default border-2'
  );
}

export function getValueForMultiSelectIntent(value: string) {
  return Object.keys(goalsList).find((key) => goalsList[key].title === value);
}
