import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { HiOutlineExternalLink as ExternalIcon } from 'react-icons/hi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Input, Label, Progress } from '@knack/asterisk-react';
import { type AxiosError } from 'axios';
import { t as i18n } from 'i18next';
import { z } from 'zod';

import { useAiQueryMutation } from '@/hooks/api/mutations/useAiQueryMutation';
import { useBillingOverviewQuery } from '@/hooks/api/queries/useBillingOverviewQuery';
import { useSession } from '@/hooks/useSession';
import { useTranslationArray } from '@/hooks/useTranslationArray';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { getFlagValue, isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { FormControl } from '@/components/ui/FormControl';
import { LoadingModal } from '@/components/ui/LoadingModal';
import { TextAreaAIAnimated } from '@/components/ui/TextAreaAnimated';
import {
  ILLEGAL_CHARACTERS_REGEX,
  MAX_AVERAGE_WORD_LENGTH,
  MAX_CHARACTERS_COUNT,
  MIN_CHARACTER_COUNT
} from './aiConstants';
import { AiSuggestions } from './AiSuggestions';

const aiInputSchema = z.object({
  query: z.string().refine((value) => {
    const isPromptBlank = value.trim() === '';
    const averageWordLength = value.replace(/\W/g, '').length / value.split(/\s/).length;
    const hasIllegalCharacters = ILLEGAL_CHARACTERS_REGEX.test(value);

    return !isPromptBlank && averageWordLength <= MAX_AVERAGE_WORD_LENGTH && !hasIllegalCharacters;
  }, i18n('components.create_app.ai.invalid_query'))
});

export type AiInputSchemaType = z.infer<typeof aiInputSchema>;

interface AiInputProps {
  setSubmitEnabled: (enabled: boolean) => void;
}

export function AiInput({ setSubmitEnabled }: AiInputProps) {
  const {
    register,
    setValue,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<AiInputSchemaType>({
    resolver: zodResolver(aiInputSchema)
  });
  const [t] = useTranslation();
  const queryValue = getValues('query');
  const session = useSession();
  const { data: billingData } = useBillingOverviewQuery();
  const [serverError, setServerError] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [usedSuggestionCount, setUsedSuggestionCount] = useState(0);
  const [numberOfCharacters, setNumberOfCharacters] = useState(0);

  const hasErrors = !!(isFormDirty && queryValue && (serverError || errors.query));
  const { createAppFromAiQuery } = useAiQueryMutation();
  const creatingPhrases = useTranslationArray('components.create_app.creating');
  const maxCount = isFlagEnabled('int_maximum_ai_prompt_char_limit')
    ? Number(getFlagValue('int_maximum_ai_prompt_char_limit')) || MAX_CHARACTERS_COUNT
    : MAX_CHARACTERS_COUNT;

  const getProgressValue = () =>
    numberOfCharacters === 0 ? 1 : (numberOfCharacters / maxCount) * 100;
  const isNewAIFlow = true; // we might be setting this under a flag after first iteration

  const onSubmit = (formData) => {
    if (errors.query) {
      return;
    }

    createAppFromAiQuery.mutate(formData.query, {
      onSuccess: async (data) => {
        let newAppUrl = '';
        const isNextGenAccess =
          isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');

        if (isNextGenAccess) {
          newAppUrl = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${
            data.slug
          }/welcome?type=ai`;
        } else {
          newAppUrl = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${
            data.slug
          }/welcome?type=ai`;
        }

        if (shouldSendAnalytics(session.user.email)) {
          await rudderStackAnalytics.track('AI - App Created', {
            groupId: session.account.id,
            app_id: data.id,
            builderVersion: isNextGenAccess ? '4' : '3',
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            status: 'success',
            suggestionsUsed: usedSuggestionCount
          });
        }
        window.location.href = newAppUrl;
      },
      onError: (error) => {
        let message;

        if ((error as AxiosError).response?.status === 429) {
          message = t('components.create_app.ai.rate_limit_error');
        } else if ((error as AxiosError).response?.status === 504) {
          message = t('components.create_app.ai.high_traffic_error');
        } else if (!(error as AxiosError).response?.status && (error as AxiosError).request) {
          message = t('components.create_app.ai.connectivity_error');
        } else {
          // Something went wrong
          message = getErrorMessage(error, t('components.create_app.ai.ask_ai_error'));
        }

        /* v8 ignore next 9 */
        if (shouldSendAnalytics(session.user.email)) {
          void rudderStackAnalytics.track('AI - Failed App Creation', {
            groupId: session.account.id,
            account_id: session.account.id,
            customer_id: billingData?.customer?.id,
            user_id: session.user.id,
            query: formData.query,
            message,
            status: 'failed',
            suggestionsUsed: usedSuggestionCount
          });
        }
        setServerError(message);
      }
    });
  };

  const onChange = (value: string) => {
    setValue('query', value, { shouldValidate: true });
    setSubmitEnabled(value.length >= MIN_CHARACTER_COUNT && value.length <= maxCount);
    setNumberOfCharacters(value.length);
    setIsFormDirty(true);
    clearErrors();
    setServerError('');
  };

  const onSuggestionClick = (query) => {
    setValue('query', query);
    setUsedSuggestionCount(usedSuggestionCount + 1);
    setSubmitEnabled(queryValue.length !== 0);
    setIsFormDirty(true);
    clearErrors();
    setServerError('');
  };

  const getBannerStateText = () => {
    const percentage = getProgressValue();
    if (numberOfCharacters < 60) {
      return 'start';
    }
    if (percentage < 75) {
      return 'continue';
    }
    if (percentage < 100) {
      return 'completed';
    }
    return 'limit_reached';
  };

  return (
    <form
      id="ai-query-form"
      data-testid="create-app-ai-query-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-4">
        <Label className="w-full text-md font-semibold text-emphasis" htmlFor="describe-ai-app">
          {t('components.create_app.ai.describe_your_app')}
        </Label>
        <p className="text-xs text-subtle">{t('components.create_app.ai.basics')}</p>
      </div>
      <Progress
        className="mb-4 h-2"
        value={getProgressValue()}
        max={100}
        intent={getProgressValue() < 75 ? 'brand' : 'green'}
      />
      <Banner className="mb-4" intent={getProgressValue() < 75 ? 'light' : 'success'}>
        <span>{t(`components.create_app.ai.progress_banner.${getBannerStateText()}`)}</span>
      </Banner>
      <FormControl className="flex flex-col justify-start">
        <div className="mb-2 flex">
          {!isNewAIFlow && <AiSuggestions onClick={onSuggestionClick} />}
        </div>
        <Input.Container className="h-full w-full">
          <TextAreaAIAnimated
            data-testid="create-app-ai-query-input"
            title={t('components.create_app.ai.ask_ai')}
            disabled={isSubmitting}
            placeholder={t('components.create_app.ai.placeholder_suggestion')}
            intent={hasErrors ? 'destructive' : 'default'}
            maxLength={maxCount}
            register={register}
            onChange={onChange}
          />
        </Input.Container>
        {hasErrors && (
          <FormControl.Message
            type={hasErrors ? 'error' : 'default'}
            className="mt-10 rounded-lg bg-destructive p-4 text-subtle"
          >
            {/* We're using just one Trans component to render all the AI errors.
                The errors need support for <a>, <br>, <span> and Icon components.
                Since we're already using the translation, we don't need to use the i18nKey prop. */}
            <Trans
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                a: <a />,
                br: <br />,
                span: <span />,
                ExternalIcon: <ExternalIcon />
              }}
            >
              {errors.query?.message || serverError}
            </Trans>
          </FormControl.Message>
        )}
        {queryValue?.length === maxCount && (
          <FormControl.Message
            type="default"
            className="text-subtle"
            data-testid="create-app-ai-max-limit-message"
          >
            {t('components.create_app.ai.max_ask_size', { maxCount })}
          </FormControl.Message>
        )}
      </FormControl>
      {(createAppFromAiQuery.isPending || createAppFromAiQuery.isSuccess) && (
        <LoadingModal phrases={creatingPhrases} />
      )}
    </form>
  );
}
