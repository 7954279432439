import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { boot, Intercom, shutdown, update } from '@intercom/messenger-js-sdk';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { dateCreatedFromMongoId } from './utils/dateCreatedFromMongoId';
import { isFlagEnabled } from './utils/flagsmith';

export function shouldInstallIntercom() {
  return import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
}

export function IntercomProvider({ children }: { children: React.ReactNode }) {
  const { data: session } = useSessionQuery();
  const { data: account } = useAccountQuery();
  const isAuthenticated = !!session?.user.id;
  const { pathname } = useLocation();
  const isNextGenApp = isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');

  const isIntercomActive = useMemo(
    () => shouldInstallIntercom() && isAuthenticated,
    [account, isAuthenticated]
  );

  const getInitParams = useCallback(() => {
    if (!account) return null;

    // Default value in case something is undefined
    let signedUpAt = new Date(Date.now());

    // shared builders don't have an account id
    if (account?.id) {
      signedUpAt = dateCreatedFromMongoId(account.id);
    } else if (session?.user?.id) {
      signedUpAt = dateCreatedFromMongoId(session.user.id);
    }

    return {
      name: `${account.session.user.first_name ?? ''} ${account.session.user?.last_name ?? ''}`.trim(),
      email: account.session.user.email,
      user_id: account.session.user.id,
      account: account.slug,
      plan_name: account.product_plan?.name,
      builder_version: isNextGenApp ? '4' : '3',
      signed_up_at: signedUpAt
    };
  }, [account]);

  // This is meant to be called on every render as detailed by the package documentation:
  // > The package keeps track of any instances needed internally, therefore re-renders due
  // > to DOM changes won't trigger re-boot of the messenger.
  // See https://www.npmjs.com/package/@intercom/messenger-js-sdk
  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      return;
    }

    Intercom({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
  });

  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      shutdown();

      return;
    }

    boot({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
    update({
      ...params
    });
  }, [getInitParams, isIntercomActive, isAuthenticated, pathname, session]);

  return children;
}
