import { useTranslation } from 'react-i18next';
import { Dialog, Spinner } from '@knack/asterisk-react';

import { useGetHistoryURLQuery } from '@/hooks/api/queries/useFlowsQuery';

interface FlowsHistoryDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export function FlowsHistoryDialog({ isOpen, setIsOpen }: FlowsHistoryDialogProps) {
  const [t] = useTranslation();
  const { data: historyIframeUrl, isLoading: isIframeLoading } = useGetHistoryURLQuery();

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(false)} data-testid="flows-history-dialog">
      <Dialog.Header>{t('components.billing.overview.flows.history')}</Dialog.Header>
      <Dialog.Content width="lg">
        {isIframeLoading ? (
          <div className="flex h-[450px] w-full items-center justify-center">
            <Spinner size="default" />
          </div>
        ) : (
          <iframe
            src={historyIframeUrl?.flowsUrl}
            title={t('components.billing.overview.flows.history')}
            sandbox="allow-scripts allow-same-origin allow-storage-access-by-user-activation allow-modals allow-popups"
            className="h-[450px] w-full max-w-[1000px] p-0"
          />
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <Dialog.Close onClick={() => setIsOpen(false)}>{t('actions.close')}</Dialog.Close>
      </Dialog.Footer>
    </Dialog>
  );
}
