import { useEffect, useState, type Dispatch, type ReactElement, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HiMinus as MinusIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { update as intercomUpdate } from '@intercom/messenger-js-sdk';
import { Banner, Button, Collapsible, Dialog, Spinner, useToast } from '@knack/asterisk-react';

import { type Account, type ProductPlan } from '@/types/account';
import { useHubspotMutation } from '@/hooks/api/mutations/useHubspotMutation';
import { useBillingOverviewQuery } from '@/hooks/api/queries/useBillingOverviewQuery';
import { useExistingCouponQuery } from '@/hooks/api/queries/useExistingCouponQuery';
import { pushToDataLayer, shouldSendAnalytics } from '@/utils/analytics';
import { getPriceWithCouponDiscount } from '@/utils/billing';
import { STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES } from '@/utils/constants';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { PaymentForm, type PaymentFormState } from '@/pages/settings/billing/payment/PaymentForm';
import { TaxExemptionMessage } from '@/pages/settings/billing/payment/TaxExemptionMessage';
import { PlanDowngradeInfo } from './PlanDowngradeInfo';

interface ConfirmPlanDialogProps {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedPlan: ProductPlan;
  accountData: Account;
  hasPaymentMethod: boolean;
  isLoading: boolean;
  closeDialog: () => void;
  onCancel: () => void;
  onPlanChangeConfirm: () => void;
  onTrialerConverted: () => void;
}

interface PricesWithCalculations {
  currentPrice: number;
  selectedPrice: number;
}

export function ConfirmPlanDialog({
  open,
  setIsOpen,
  selectedPlan,
  accountData,
  hasPaymentMethod,
  isLoading,
  closeDialog,
  onCancel,
  onPlanChangeConfirm,
  onTrialerConverted
}: ConfirmPlanDialogProps) {
  const { presentToast } = useToast();
  const [t] = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasConfirmedTrialToStarter, setHasConfirmedTrialToStarter] = useState(false);

  const { currentPlan, numberOfApps, numberOfBuilders } = accountData;
  const isInternalAccount = currentPlan.id === 'knack_internal';

  const isTrialToStarter = currentPlan.id === 'trial' && selectedPlan.id.startsWith('starter');
  const selectedPlanAppsLimit = selectedPlan.apps;
  const selectedPlanBuildersLimit = selectedPlan.builders;

  const isAppsLimitExceeded = numberOfApps > selectedPlanAppsLimit;
  const isBuildersLimitExceeded = numberOfBuilders > selectedPlanBuildersLimit;

  const canContinueWithPlan = !isAppsLimitExceeded && !isBuildersLimitExceeded;
  const paymentFormId = 'payment-form-dialog';

  const selectedFrequency = selectedPlan.frequency ? 'year' : 'month';
  const isFrequencyChange = currentPlan.frequency !== selectedPlan.frequency;
  const hubspotMutation = useHubspotMutation();
  const { data: billingData } = useBillingOverviewQuery();

  const { data: existingCoupon } = useExistingCouponQuery();
  const [isCalculatingFinalPrices, setIsCalculatingFinalPrices] = useState(true);
  const currentPrice = currentPlan.frequency ? currentPlan.price * 10 : currentPlan.price;
  const selectedPrice = selectedFrequency === 'year' ? selectedPlan.price * 10 : selectedPlan.price;

  const [pricesWithCalculations, setPricesWithCalculations] =
    useState<PricesWithCalculations | null>(null);

  useEffect(() => {
    setHasConfirmedTrialToStarter(false);
  }, [currentPlan.id, selectedPlan.id]);

  useEffect(() => {
    setIsCalculatingFinalPrices(true);
    if (existingCoupon?.coupon) {
      setPricesWithCalculations({
        // Stripe already gives us the upcoming total with the coupon applied
        currentPrice: billingData?.upcoming?.total
          ? billingData.upcoming.total / 100
          : getPriceWithCouponDiscount(currentPrice, existingCoupon.coupon, undefined, false),
        selectedPrice: getPriceWithCouponDiscount(
          selectedPrice,
          existingCoupon.coupon,
          undefined,
          true
        )
      });
    } else {
      setPricesWithCalculations({
        currentPrice,
        selectedPrice
      });
    }
    setIsCalculatingFinalPrices(false);
  }, [existingCoupon, selectedPlan.id]);

  const onFormStateChange = (formState: PaymentFormState) => {
    setIsSubmitting(formState.isFormSubmitting);
  };

  const onFormSuccess = () => {
    presentToast({
      title: t('components.billing.plans.plan_successfully_updated_trial')
    });

    if (shouldSendAnalytics(accountData?.session.user?.email)) {
      pushToDataLayer('paid-subscription', {
        subscription: selectedPlan.name,
        // Yearly plans are multiplied by 10 because we are giving 2-months for free
        value: selectedFrequency === 'year' ? selectedPrice * 10 : selectedPrice
      });
    }
    if (import.meta.env.PUBLIC_IS_PRODUCTION === 'true') {
      void hubspotMutation.mutate({
        email: accountData?.session.user?.email,
        firstName: accountData?.session.user?.first_name,
        lastName: accountData?.session.user?.last_name
      });
    }

    if (import.meta.env.PUBLIC_INTERCOM_APP_ID) {
      intercomUpdate({
        subscription_start_date: Math.floor(Date.now() / 1000),
        plan_name: selectedPlan.name
      });
    }

    onTrialerConverted();
    closeDialog();
  };

  const dialogTitle = () => {
    if (isTrialToStarter && !hasConfirmedTrialToStarter) {
      return t('components.billing.plans.confirm_starter_plan');
    }

    if (isInternalAccount) {
      return t('keywords.warning');
    }

    if (!canContinueWithPlan) {
      return t('components.billing.plans.over_the_limit');
    }

    if (!hasPaymentMethod) {
      return t('components.billing.plans.upgrade_to_plan', {
        plan: selectedPlan.name
      });
    }

    return t('components.billing.plans.confirm_plan_change');
  };

  const dialogDescription = () => {
    if (!hasPaymentMethod && !isInternalAccount) {
      let priceToDisplay = selectedPrice;

      if (currentPlan.id === 'trial' && selectedFrequency === 'month') {
        if (selectedPlan.id.startsWith('starter')) {
          priceToDisplay = STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.amount.starter;
        }
        if (selectedPlan.id.startsWith('pro')) {
          priceToDisplay = STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.amount.pro;
        }
      }
      return t(`components.billing.plans.price_${selectedFrequency}`, {
        price: priceToDisplay
      });
    }

    return undefined;
  };

  const dialogContent = () => {
    if (isTrialToStarter && !hasConfirmedTrialToStarter) {
      return (
        <>
          <div className="mb-6">
            <p>{t('components.billing.plans.confirm_starter_plan_description')}</p>
          </div>
          <Banner intent="warning">
            {t('components.billing.plans.confirm_starter_plan_warning')}
          </Banner>
        </>
      );
    }

    if (isInternalAccount) {
      return <p>{t('components.billing.plans.internal_knack_accounts')}</p>;
    }

    if (!hasPaymentMethod) {
      return (
        <>
          <PaymentForm
            formId={paymentFormId}
            shouldShowPromoCodeInput
            onFormStateChange={onFormStateChange}
            onFormSuccess={onFormSuccess}
            selectedPlan={selectedPlan}
          />

          <Collapsible
            onOpenChange={setOpenCollapsible}
            className="cursor-pointer rounded-lg bg-muted"
          >
            <Collapsible.Trigger asChild className="appearance-none">
              <div className="flex w-full items-center justify-between p-6">
                <h3 className="font-medium text-emphasis">
                  {t('components.billing.plans.tax_exemption')}
                </h3>
                {!openCollapsible ? <PlusIcon /> : <MinusIcon />}
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content className="collapsible-animation mt-2 px-6 pb-6">
              <TaxExemptionMessage />
            </Collapsible.Content>
          </Collapsible>
        </>
      );
    }

    if (canContinueWithPlan) {
      const isDowngradingToProOrBelow =
        selectedPlan.level < currentPlan.level && selectedPlan.level < 3;

      return (
        <>
          {!existingCoupon || isCalculatingFinalPrices ? (
            <Spinner />
          ) : (
            <>
              <p className="mb-2">
                {t('components.billing.plans.current_plan')}:{' '}
                {currentPlan.frequency
                  ? t('components.billing.plans.change_yearly', {
                      plan: currentPlan.name,
                      price: pricesWithCalculations?.currentPrice
                        ? Math.round(pricesWithCalculations.currentPrice / 12)
                        : Math.round(currentPrice / 12),
                      yearPrice: pricesWithCalculations?.currentPrice
                        ? pricesWithCalculations.currentPrice
                        : currentPrice
                    })
                  : t('components.billing.plans.change_monthly', {
                      plan: currentPlan.name,
                      price: pricesWithCalculations?.currentPrice
                        ? pricesWithCalculations.currentPrice
                        : currentPrice
                    })}
              </p>
              <p>
                {t('components.billing.plans.new_plan')}:{' '}
                {selectedPlan.frequency
                  ? t('components.billing.plans.change_yearly', {
                      plan: selectedPlan.name,
                      price: pricesWithCalculations?.selectedPrice
                        ? Math.round(pricesWithCalculations.selectedPrice / 12)
                        : Math.round(selectedPrice / 12),
                      yearPrice: pricesWithCalculations?.selectedPrice
                        ? pricesWithCalculations.selectedPrice
                        : selectedPrice
                    })
                  : t('components.billing.plans.change_monthly', {
                      plan: selectedPlan.name,
                      price: pricesWithCalculations?.selectedPrice
                        ? pricesWithCalculations.selectedPrice
                        : selectedPrice
                    })}
              </p>
            </>
          )}
          {isFrequencyChange && (
            <p className="mt-6">
              {selectedPlan.frequency
                ? t('components.billing.plans.confirm_plan_frequency_change_2_month_discount')
                : t('components.billing.plans.confirm_plan_frequency_change_2_month_discount_loss')}
            </p>
          )}

          {isDowngradingToProOrBelow && (
            <PlanDowngradeInfo currentPlanName={currentPlan.name} selectedPlan={selectedPlan} />
          )}
        </>
      );
    }

    // At this point we know we can't continue with the plan selection due to limits exceeded
    const content: ReactElement[] = [];
    if (isAppsLimitExceeded) {
      content.push(
        <Banner key="app-limit-exceeded" intent="warning" className="[&:not(:last-child)]:mb-6">
          {t('components.billing.plans.you_have_more_apps', {
            numberOfApps,
            selectedPlanAppsLimit
          })}
        </Banner>
      );
    }
    if (isBuildersLimitExceeded) {
      content.push(
        <Banner key="builder-limit-exceeded" intent="warning">
          {t('components.billing.plans.you_have_more_shared_builders', {
            numberOfBuilders,
            selectedPlanBuildersLimit
          })}
        </Banner>
      );
    }

    return content;
  };

  const dialogFooter = () => {
    if (isTrialToStarter && !hasConfirmedTrialToStarter) {
      return (
        <Dialog.Footer intent="borderless">
          <Dialog.Close asChild>
            <Button intent="minimal" onClick={onCancel}>
              {t('components.billing.plans.back_to_plans')}
            </Button>
          </Dialog.Close>
          <Button isLoading={isLoading} onClick={() => setHasConfirmedTrialToStarter(true)}>
            {t('components.billing.plans.continue_with_starter')}
          </Button>
        </Dialog.Footer>
      );
    }

    if (!hasPaymentMethod) {
      return (
        <Dialog.Footer intent="borderless">
          <Dialog.Close asChild>
            <Button intent="minimal" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
          </Dialog.Close>
          <Button type="submit" isLoading={isSubmitting} form={paymentFormId}>
            {t('actions.upgrade')}
          </Button>
        </Dialog.Footer>
      );
    }

    if (canContinueWithPlan) {
      if (selectedPlan.level < currentPlan.level) {
        return (
          <Dialog.Footer intent="borderless">
            <Dialog.Close asChild>
              <Button intent="minimal" onClick={onCancel}>
                {t('actions.cancel')}
              </Button>
            </Dialog.Close>
            <Button intent="destructive" isLoading={isLoading} onClick={onPlanChangeConfirm}>
              {t('actions.downgrade')}
            </Button>
          </Dialog.Footer>
        );
      }

      return (
        <Dialog.Footer intent="borderless">
          <Dialog.Close asChild>
            <Button intent="minimal" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
          </Dialog.Close>
          <Button isLoading={isLoading} onClick={onPlanChangeConfirm}>
            {t('actions.confirm')}
          </Button>
        </Dialog.Footer>
      );
    }

    return (
      <Dialog.Footer intent="borderless">
        <Dialog.Close asChild>
          <Button intent="minimal" onClick={onCancel}>
            {t('actions.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Footer>
    );
  };

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DashboardDialogContent
        headerTitle={dialogTitle()}
        headerDescription={dialogDescription()}
        footer={dialogFooter()}
        width={!hasPaymentMethod && !isInternalAccount ? 'lg' : 'sm'}
      >
        {dialogContent()}
      </DashboardDialogContent>
    </Dialog>
  );
}
