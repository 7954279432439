import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIsIframeSession } from '@/hooks/useIsIframeSession';

type PostSignUpProviderContextState = {
  isSetupWizardEnabled: boolean;
  setIsSetupWizardEnabled: (isSetupWizardEnabled: boolean) => void;
};

const contextDefaultValues: PostSignUpProviderContextState = {
  isSetupWizardEnabled: false,
  setIsSetupWizardEnabled: () => {}
};

const PostSignUpContext = createContext<PostSignUpProviderContextState>(contextDefaultValues);

export function PostSignUpProvider({ children }: { children: ReactNode }) {
  const isIframeSession = useIsIframeSession();

  const [isSetupWizardEnabled, setIsSetupWizardEnabled] = useState(
    contextDefaultValues.isSetupWizardEnabled
  );
  const [searchParams] = useSearchParams();
  const searchParamsSerialized = searchParams.toString();
  const urlSearchParams = searchParamsSerialized ? `?${searchParamsSerialized}` : '';

  const isSetupWizardEnabledValues = useMemo<PostSignUpProviderContextState>(
    () => ({
      isSetupWizardEnabled,
      setIsSetupWizardEnabled
    }),
    [isSetupWizardEnabled, setIsSetupWizardEnabled]
  );

  useEffect(() => {
    if (!isIframeSession) {
      return;
    }

    window.parent.location.href = `${import.meta.env.PUBLIC_DASHBOARD_URL}${urlSearchParams}`;
  }, [isIframeSession, urlSearchParams]);

  return (
    <PostSignUpContext.Provider value={isSetupWizardEnabledValues}>
      {children}
    </PostSignUpContext.Provider>
  );
}

export const usePostSignUpContext = () => useContext(PostSignUpContext);
