import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Banner,
  Button,
  Dialog,
  InputSearch,
  useToast,
  type BannerProps
} from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { Route } from '@/enums';
import { useAccountRestrictions } from '@/hooks/account/useAccountRestrictions';
import { useCreateTrialAccountMutation } from '@/hooks/api/mutations/useCreateTrialAccountMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useAppsQuery } from '@/hooks/api/queries/useAppsQuery';
import { useAccountFlowsStatusQuery } from '@/hooks/api/queries/useFlowsQuery';
import { APPS_PAGE_TABS, usePageTabs } from '@/hooks/usePageTabs';
import { useIsSharedBuilderOnly, useSession } from '@/hooks/useSession';
import { usePostUpdatePlanContext } from '@/contexts/PostUpdatePlanContext';
import { DISMISS_BANNER_SESSION_KEY, RESTRICTION_ID } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { ChecklistsNavButton } from '@/components/checklists/ChecklistsNavButton';
import { PageHeader } from '@/components/layout';
import { MobileNav } from '@/components/navigation/mobile-nav';
import { SideNav } from '@/components/navigation/sidenav';
import { RestrictionModal } from '@/pages/apps/app-modals/RestrictionModal';
import { CreateAppButton } from '@/pages/apps/create-app/CreateAppButton';
import { MyApps } from '@/pages/apps/MyApps';
import { SharedApps } from '@/pages/apps/SharedApps';
import { WelcomeDialog } from '@/pages/apps/WelcomeDialog';
import { AppModalFormButtons } from './app-modals/AppModalFormButtons';
import { PageHeaderSkeleton } from './PageHeaderSkeleton';

interface RestrictionAttributes {
  id: string;
  title: string;
  message: string | React.ReactNode;
  buttonText?: string;
  intent: BannerProps['intent'];
  icon?: React.FC<React.ComponentProps<'svg'>>;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  dismissible?: boolean;
}

interface Restrictions {
  frozen: RestrictionAttributes;
  suspended: RestrictionAttributes;
  suspensionWarning: RestrictionAttributes;
  appCreationError: RestrictionAttributes;
  reachedAppLimit: RestrictionAttributes;
  trialExpired: RestrictionAttributes;
  isSharedBuilderOnly: RestrictionAttributes;
  isTrialExtended: RestrictionAttributes;
}

export interface DisablingRestrictions {
  createApp?: boolean;
  disableInteraction?: boolean;
}

const restrictionDefaultState: RestrictionAttributes = {
  id: '',
  title: '',
  message: '',
  buttonText: '',
  icon: undefined,
  intent: 'default'
};

export function AppsPage({ activeTab = '' }: { activeTab?: '' | 'shared' }) {
  const [t, { language }] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const session = useSession();
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { data: account, isLoading: isAccountFetchLoading } = useAccountQuery();
  const { isLoading: isAppsFetchLoading } = useAppsQuery();
  const { mutate: createTrialAccount } = useCreateTrialAccountMutation();
  const { presentToast } = useToast();
  const { tabConfig } = usePageTabs(APPS_PAGE_TABS);
  const accountRestrictions = useAccountRestrictions();

  const { shouldShowAutoIncreaseBanner, setShouldShowAutoIncreaseBanner } =
    usePostUpdatePlanContext();

  const isHipaa = account?.settings?.hipaa.enabled;
  const isTrialExtended = account?.is_trial_extended && account?.status === 'beta';

  const disabledTabs = isSharedBuilderOnly ? [APPS_PAGE_TABS.tabs.MyApps.path] : [];
  const [search, setSearch] = useState('');
  const [dismissedBannersLocalStorage, setDismissedBannersLocalStorage] = useLocalStorage(
    DISMISS_BANNER_SESSION_KEY,
    ['']
  );
  const [sessionDismissedBanners] = useState(
    JSON.parse(sessionStorage.getItem(DISMISS_BANNER_SESSION_KEY) || '[]')
  );
  const [openTrialDialog, setOpenTrialDialog] = useState(false);
  const [trialErrorMessage, setTrialErrorMessage] = useState('');
  const [appCreationError, setAppCreationError] = useState('');
  const [shouldShowRestrictionModal, setShouldShowRestrictionModal] = useState(false);
  const { data: flowsStatus } = useAccountFlowsStatusQuery(isSharedBuilderOnly);
  function handleDismissBanner(id: string) {
    setDismissedBannersLocalStorage([...dismissedBannersLocalStorage, id]);
    setAppCreationError('');
  }

  const restrictions: DisablingRestrictions = useMemo(() => {
    if (!accountRestrictions) {
      return {};
    }
    const { frozen, suspended, reachedAppLimit, reachedStorageLimit, trialExpired } =
      accountRestrictions;

    const disableCreateApps = [
      frozen,
      suspended,
      trialExpired,
      reachedAppLimit,
      reachedStorageLimit,
      isSharedBuilderOnly
    ];
    const disableInteraction = [frozen, suspended, trialExpired];

    return {
      createApp: disableCreateApps.includes(true),
      disableInteraction: disableInteraction.includes(true)
    };
  }, [accountRestrictions, isSharedBuilderOnly]);

  const APP_RESTRICTIONS: Restrictions = useMemo(
    () => ({
      frozen: {
        id: RESTRICTION_ID.frozen,
        title: t('components.restrictions.frozen.title'),
        message: t('components.restrictions.frozen.message'),
        buttonText: t('components.restrictions.frozen.button'),
        intent: 'warning',
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Account}/${Route.Management}`,
            search: location.search
          })
      },
      suspended: {
        id: RESTRICTION_ID.suspended,
        title: t('components.restrictions.suspended.title'),
        message: t('components.restrictions.suspended.message'),
        buttonText: t('components.restrictions.suspended.button'),
        intent: 'destructive',
        icon: ExclamationIcon,
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Payment}`,
            search: location.search
          })
      },
      suspensionWarning: {
        id: RESTRICTION_ID.suspensionWarning,
        title: t('components.restrictions.suspension_warning.title'),
        message: t('components.restrictions.suspension_warning.message'),
        buttonText: t('components.restrictions.suspension_warning.button'),
        intent: 'warning',
        icon: ExclamationIcon,
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Payment}`,
            search: location.search
          })
      },
      reachedAppLimit: {
        id: RESTRICTION_ID.reachedAppLimit,
        title: t('components.restrictions.reached_app_limit.title'),
        message: t('components.restrictions.reached_app_limit.message'),
        buttonText: t('components.restrictions.reached_app_limit.button'),
        intent: 'warning',
        icon: ExclamationIcon,
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
            search: location.search
          }),
        dismissible: true
      },
      appCreationError: {
        id: RESTRICTION_ID.appCreationError,
        title: t('components.create_app.create_app_error_title'),
        message: appCreationError,
        intent: 'destructive',
        icon: ExclamationIcon,
        dismissible: true
      },
      trialExpired: {
        id: RESTRICTION_ID.trialExpired,
        title: t('components.restrictions.trial_expired.title'),
        message: t('components.restrictions.trial_expired.message'),
        buttonText: t('components.restrictions.trial_expired.button'),
        intent: 'gradient',
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
            search: location.search
          })
      },
      isSharedBuilderOnly: {
        id: RESTRICTION_ID.startTrial,
        title: t('components.restrictions.only_shared_builder.title'),
        message: t('components.restrictions.only_shared_builder.message'),
        buttonText: t('components.restrictions.only_shared_builder.button'),
        intent: 'gradient',
        action: () => setOpenTrialDialog(true)
      },
      isTrialExtended: {
        id: RESTRICTION_ID.trialExtended,
        title: t('components.restrictions.trial_extended.title'),
        message: (
          <Trans i18nKey="components.restrictions.trial_extended.message">
            <a
              href="https://meetings.hubspot.com/ranasha/knack-discovery-call-15-mins"
              target="_blank"
              rel="noreferrer"
              className="font-bold underline"
            >
              schedule a 1:1 consultation
            </a>
          </Trans>
        ),
        buttonText: t('components.restrictions.trial_extended.button'),
        intent: 'gradient',
        action: () =>
          navigate({
            pathname: `/${Route.Settings}/${Route.Billing}/${Route.Plans}`,
            search: location.search
          }),
        dismissible: true
      }
    }),
    [navigate, t, location.search, appCreationError]
  );

  const restriction = useMemo(
    () => {
      if (accountRestrictions?.suspended) {
        return APP_RESTRICTIONS.suspended;
      }
      if (accountRestrictions?.suspensionWarning) {
        return APP_RESTRICTIONS.suspensionWarning;
      }
      if (accountRestrictions?.frozen) {
        return APP_RESTRICTIONS.frozen;
      }
      if (accountRestrictions?.trialExpired) {
        return APP_RESTRICTIONS.trialExpired;
      }
      if (isTrialExtended) {
        return APP_RESTRICTIONS.isTrialExtended;
      }
      if (accountRestrictions?.reachedAppLimit) {
        return APP_RESTRICTIONS.reachedAppLimit;
      }
      if (appCreationError) {
        return APP_RESTRICTIONS.appCreationError;
      }
      if (isSharedBuilderOnly) {
        return APP_RESTRICTIONS.isSharedBuilderOnly;
      }
      return restrictionDefaultState;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountRestrictions, dismissedBannersLocalStorage, appCreationError]
  );

  const restrictionBannerDismissed = useMemo(() => {
    if (accountRestrictions?.reachedAppLimit) {
      return (
        dismissedBannersLocalStorage.includes(RESTRICTION_ID.reachedAppLimit) ||
        sessionDismissedBanners.includes(RESTRICTION_ID.reachedAppLimit)
      );
    }

    if (isTrialExtended) {
      return !dismissedBannersLocalStorage.includes(RESTRICTION_ID.trialExtended);
    }

    return false;
  }, [
    accountRestrictions?.reachedAppLimit,
    dismissedBannersLocalStorage,
    isTrialExtended,
    sessionDismissedBanners
  ]);

  function createAccountFromSharedBuilder() {
    createTrialAccount(undefined, {
      onSuccess: () => {
        presentToast({
          title: t('components.start_trial_modal.toast_message_success')
        });
        setOpenTrialDialog(false);
        setShouldShowRestrictionModal(false);
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.start_trial_modal.error_message'));
        setTrialErrorMessage(message);
      }
    });
  }

  const navigateToBilling = () => {
    navigate({
      pathname: `/${Route.Settings}/${Route.Billing}`,
      search: location.search
    });
  };

  return (
    <Dialog open={openTrialDialog} onOpenChange={setOpenTrialDialog}>
      <div className="grid-wrapper min-h-screen md:grid">
        <MobileNav />
        <SideNav />
        <main className="flex w-full max-w-[1200px] flex-col overflow-hidden p-4 md:p-12 2xl:max-w-[1800px]">
          <WelcomeDialog />
          <div className="block justify-between md:flex">
            {!account && isAccountFetchLoading ? (
              <PageHeaderSkeleton />
            ) : (
              <>
                <PageHeader
                  heading={t('components.apps.apps')}
                  tabConfig={tabConfig}
                  activeTab={activeTab}
                  disabledTabs={disabledTabs}
                />
                <div className="my-8 flex w-full flex-col justify-center gap-4 md:my-0 md:w-auto md:flex-row md:justify-normal">
                  <InputSearch
                    aria-label="Search apps"
                    className="w-full py-1.5 md:min-w-[200px]"
                    value={search}
                    placeholder={t('components.apps.search')}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="flex flex-row items-end justify-end gap-4 md:flex-col md:justify-normal lg:flex-row lg:items-start">
                    {session.user.marketing?.checklist &&
                      !isFlagEnabled('full_nextgen_access') &&
                      !isFlagEnabled('only_nextgen_access') && <ChecklistsNavButton />}
                    <CreateAppButton
                      disabled={restrictions.createApp || isAppsFetchLoading}
                      isHipaa={isHipaa}
                      setAppCreationError={setAppCreationError}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {shouldShowAutoIncreaseBanner && flowsStatus?.isInitialized && (
            <Banner
              intent="success"
              title={t('components.billing.addons.transactions.auto_increase.banner.title')}
              className="mb-6"
              closeMode="text"
              closeLabel={t('actions.dismiss')}
              onAction={() => setShouldShowAutoIncreaseBanner(false)}
              actionLabel={t('actions.dismiss')}
            >
              <Banner.Message>
                <span>
                  {t('components.billing.addons.transactions.auto_increase.banner.content')}
                </span>
                <p>
                  <Button
                    intent="link"
                    className="text-emphasis"
                    onClick={() => navigateToBilling()}
                  >
                    {t('components.billing.addons.transactions.auto_increase.banner.link')}
                  </Button>
                  <span>
                    {t('components.billing.addons.transactions.auto_increase.banner.end_text')}
                  </span>
                </p>
              </Banner.Message>
            </Banner>
          )}
          {!!restriction.id && !restrictionBannerDismissed && (
            <Banner
              intent={restriction.intent}
              icon={restriction.icon}
              title={restriction.title}
              className="mb-6"
              closeMode="text"
              closeLabel={t('actions.dismiss')}
              onAction={restriction.action}
              actionLabel={restriction.buttonText}
              onClose={
                restriction.dismissible ? () => handleDismissBanner(restriction.id) : undefined
              }
            >
              <Banner.Message className="text-xs">{restriction.message}</Banner.Message>
            </Banner>
          )}
          {activeTab === APPS_PAGE_TABS.tabs.MyApps.path && (
            <MyApps
              onOpenRestrictionModal={() => setShouldShowRestrictionModal(true)}
              restrictions={restrictions}
              search={search}
            />
          )}
          {activeTab === APPS_PAGE_TABS.tabs.SharedApps.path && <SharedApps search={search} />}
          <Helmet>
            <title
              lang={language}
            >{`${t('components.apps.apps')} - ${t('titles.app_name')}`}</title>
          </Helmet>

          <RestrictionModal
            open={shouldShowRestrictionModal}
            setIsOpen={setShouldShowRestrictionModal}
            title={restriction.title}
            message={restriction.message}
            buttonText={restriction.buttonText}
            onButtonClick={restriction.action}
          />
        </main>
      </div>
      <Dialog.Content className="flex flex-col bg-default">
        <Dialog.MainContent>
          <Dialog.Header className="text-xl font-medium">
            {t('components.start_trial_modal.title')}
          </Dialog.Header>
          <p className="my-12">{t('components.start_trial_modal.message')}</p>
          {trialErrorMessage && (
            <Banner intent="destructive" icon={ExclamationIcon} className="mb-12">
              <Banner.Message>{trialErrorMessage}</Banner.Message>
            </Banner>
          )}
        </Dialog.MainContent>
        <AppModalFormButtons
          submitButtonI18nKey="components.start_trial_modal.start_trial"
          onSubmit={() => createAccountFromSharedBuilder()}
          onBack={() => setTrialErrorMessage('')}
        />
      </Dialog.Content>
    </Dialog>
  );
}
