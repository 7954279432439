import React, { type ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiXMark as CloseIcon, HiChevronLeft as GoBackIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface WizardLayoutProps {
  children: React.ReactNode;
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
  contentWidth?: 'sm' | 'md' | 'lg' | 'full';
  hasHeader?: boolean;
  hasFooter?: boolean;
  headerContent?: ReactNode;
  dataTestid?: string;
}

export function WizardLayout({
  children,
  title,
  onBack,
  onClose,
  contentWidth,
  hasHeader = true,
  hasFooter = false,
  headerContent,
  dataTestid
}: WizardLayoutProps) {
  const [t, { language }] = useTranslation();

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  const onBackHandler = () => {
    if (onBack) {
      onBack();
    }
  };

  return (
    <>
      {hasHeader && (
        <div className="flex h-16 items-center bg-base px-7 shadow-sm" data-testid={dataTestid}>
          <div className="mr-auto flex items-center">
            {onBack && (
              <Button
                type="button"
                intent="minimalStandalone"
                className="mr-6 h-auto p-1 text-base text-default"
                onClick={onBackHandler}
              >
                <GoBackIcon size={20} className="mr-2" />
                {t('actions.back')}
              </Button>
            )}
            <h2 className="font-semibold text-emphasis">{title}</h2>
            {headerContent}
          </div>
          {onClose && (
            <Button
              type="button"
              intent="minimalStandalone"
              className="h-auto p-0"
              onClick={onCloseHandler}
            >
              <CloseIcon size={20} />
              <span className="sr-only">{t('actions.close')}</span>
            </Button>
          )}
        </div>
      )}
      <div
        className={cn('mx-auto mt-7 max-w-[520px] px-7', {
          'max-w-[680px]': contentWidth === 'md',
          'max-w-[840px]': contentWidth === 'lg',
          'max-w-full': contentWidth === 'full',
          'pb-24': hasFooter
        })}
      >
        {children}
      </div>
      <Helmet>
        <title lang={language}>{t('titles.app_name')}</title>
      </Helmet>
    </>
  );
}
