import {
  featureItem,
  recordsAddonItem,
  storageAddonItem,
  transactionAddonPacks,
  type ApiAddonItem
} from './addonsConstants';

export const checkAddons = (addons) => {
  if (addons) {
    return (
      addons.api.length > 0 ||
      addons.transactions.length > 0 ||
      addons.records.length > 0 ||
      addons.storage.length > 0 ||
      (addons.sso && addons.sso.length > 0)
    );
  }
  return false;
};

export const getFinalPrice = (billingData, planPrice) => {
  if (billingData?.upcoming?.total_discount_amounts?.length > 0) {
    return billingData.upcoming.total / 100;
  }
  return planPrice;
};

export const getAddonPrice = (
  addOnType: string,
  addOnId: string,
  apiPlanWithDetails?: ApiAddonItem
) => {
  let price = 0;
  switch (addOnType) {
    case 'api':
      price = apiPlanWithDetails?.price ?? 0;
      return price;
    case 'records':
      price = recordsAddonItem.price;
      return price;
    case 'storage':
      price = storageAddonItem.price;
      return price;
    case 'transactions':
      price =
        Object.values(transactionAddonPacks).find(
          (pack) => pack.testId === addOnId || pack.prodId === addOnId
        )?.price ?? 0;
      return price;
    case 'sso':
    case 'seo':
      price = featureItem[addOnId];
      return price;
    default:
      return price;
  }
};
