import { Trans, useTranslation } from 'react-i18next';
import { Card, Divider } from '@knack/asterisk-react';

import type { ProductPlan } from '@/types/account';
import { PLAN_FREQUENCY, type PlanFrequency } from '@/types/plans';
import { STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES } from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { SelectPlanButton } from '@/pages/settings/billing/plans/SelectPlanButton';

interface PlanCardBaseProps {
  knackPlan: ProductPlan;
  originalPlan: ProductPlan | undefined;
  planFrequency: PlanFrequency;
  isDowngradeAllowed: boolean;
  onPlanSelect: (plan: ProductPlan) => void;
  className?: string;
}
function PlanCardBase({
  knackPlan,
  originalPlan,
  planFrequency,
  isDowngradeAllowed,
  onPlanSelect,
  className = ''
}: PlanCardBaseProps) {
  const [t] = useTranslation();

  const planTestId = `change-plan-${knackPlan.name.toLowerCase().replace(/\s/g, '-')}`;

  const supportTypeToDisplay = (plan: ProductPlan) => {
    if (plan.id.includes('plus') || plan.id.includes('agency')) {
      return 'Premier';
    }
    switch (plan.name) {
      case 'Starter':
        return 'Basic';
      case 'Pro':
        return 'Priority';
      case 'Corporate':
        return 'Premier';
      default:
        return '';
    }
  };

  const cardClassesBase = 'flex flex-col p-6 sm:p-6 h-[400px]';
  const cardClasses = cn(cardClassesBase, className);

  // New Starter & Pro Subscription Discount Campaigns (TE-941). Needed to display the promo price on the Card
  const starterDiscountCampaign = STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.amount.starter;
  const proDiscountCampaign = STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.amount.pro;
  const calculatePriceOnDiscountCampaign = (price: number) => {
    if (originalPlan?.id === 'trial') {
      switch (knackPlan.name) {
        case 'Starter':
          return starterDiscountCampaign;
        case 'Pro':
          return proDiscountCampaign;
        default:
          return price;
      }
    }

    return price;
  };

  /**
   * Check if the customer is trial and the plan to render is Starter or Pro
   * In order to apply discount information
   * @returns
   */
  const renderPrice = () => {
    if (planFrequency === PLAN_FREQUENCY.Monthly) {
      if (
        originalPlan?.id === 'trial' &&
        (knackPlan.name === 'Starter' || knackPlan.name === 'Pro')
      ) {
        return (
          <>
            <span className="line-through">${knackPlan.price}</span>
            <span className="font-semibold">
              &nbsp;
              {t('components.billing.plans.price_month', {
                price: calculatePriceOnDiscountCampaign(knackPlan.price)
              })}
              {t('components.billing.plans.additional_discount')}
            </span>
            <p className="text-xs">
              {t('components.billing.plans.additional_discount_info', {
                price: knackPlan.price
              })}
            </p>
          </>
        );
      }
      return (
        <>
          {t('components.billing.plans.price_month', {
            price: calculatePriceOnDiscountCampaign(knackPlan.price)
          })}
        </>
      );
    }
    return (
      <span>
        <span className="mr-1 text-subtle line-through">${knackPlan.price.toLocaleString()}</span>
        {t('components.billing.plans.price_month', {
          price: Math.round((knackPlan.price * 10) / 12)
        })}
      </span>
    );
  };

  return (
    <Card className={cardClasses} data-testid={planTestId}>
      <h2 className="mb-2 text-xl font-medium text-emphasis">{knackPlan.name}</h2>
      <span className="mb-6">
        <span>{renderPrice()}</span>
        {planFrequency === PLAN_FREQUENCY.Yearly && (
          <p>{t('components.billing.plans.billed_yearly')}</p>
        )}
      </span>
      <div className="mb-6 space-y-3">
        <p>
          <Trans
            i18nKey="components.billing.plans.records_amount"
            values={{ records: knackPlan.records.toLocaleString() }}
          >
            <span className="font-semibold">Records</span>
          </Trans>
        </p>
        <Divider />
        <p>
          <Trans
            i18nKey="components.billing.plans.storage_amount"
            values={{ storage: knackPlan.storage }}
          >
            <span className="font-semibold">Storage</span>
          </Trans>
        </p>
        <Divider />
        <p>
          <Trans i18nKey="components.billing.plans.apps_amount" values={{ apps: knackPlan.apps }}>
            <span className="font-semibold">Apps</span>
          </Trans>
        </p>
        <Divider />
        <p>
          <Trans
            i18nKey="components.billing.plans.support"
            values={{ supportType: supportTypeToDisplay(knackPlan) }}
          >
            <span className="font-semibold">Basic</span> support
          </Trans>
        </p>
      </div>

      {originalPlan && (
        <SelectPlanButton
          knackPlan={knackPlan}
          currentPlan={originalPlan}
          onPlanSelect={onPlanSelect}
          isDowngradeAllowed={isDowngradeAllowed}
        />
      )}
    </Card>
  );
}

function PlanCardRecommended({
  knackPlan,
  originalPlan,
  planFrequency,
  isDowngradeAllowed,
  onPlanSelect,
  className = ''
}: PlanCardBaseProps) {
  const [t] = useTranslation();

  return (
    <div>
      <div className="rounded-t-lg bg-pink-600 p-1 text-center font-semibold text-white">
        {t('components.billing.plans.recommended')}
      </div>
      <PlanCardBase
        knackPlan={knackPlan}
        originalPlan={originalPlan}
        planFrequency={planFrequency}
        isDowngradeAllowed={isDowngradeAllowed}
        onPlanSelect={onPlanSelect}
        className={cn('rounded-t-none border border-pink-600 bg-muted', className)}
      />
    </div>
  );
}

export function PlanCard({
  originalPlan,
  knackPlan,
  planFrequency,
  isDowngradeAllowed,
  onPlanSelect,
  className = ''
}: PlanCardBaseProps) {
  const isRecommendedUpgradePlan = originalPlan?.id === 'trial' && knackPlan.id.startsWith('pro');

  return isRecommendedUpgradePlan ? (
    <PlanCardRecommended
      knackPlan={knackPlan}
      originalPlan={originalPlan}
      planFrequency={planFrequency}
      isDowngradeAllowed={isDowngradeAllowed}
      onPlanSelect={onPlanSelect}
      className={className}
    />
  ) : (
    <PlanCardBase
      knackPlan={knackPlan}
      originalPlan={originalPlan}
      planFrequency={planFrequency}
      isDowngradeAllowed={isDowngradeAllowed}
      onPlanSelect={onPlanSelect}
      className={className}
    />
  );
}
