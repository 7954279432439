import { type IconType } from 'react-icons';
import {
  HiUser as AccountIcon,
  HiSquares2X2 as AppsIcon,
  HiCurrencyDollar as BillingIcon,
  HiGlobeAlt as CommunityIcon,
  HiLightBulb as GettingStartedIcon,
  HiDocumentText as HelpArticlesIcon,
  HiHeart as HireAnExpertIcon,
  HiLockClosed as SecurityIcon
} from 'react-icons/hi2';

import { Route } from '@/enums';
import { useIsSharedBuilderOnly } from '@/hooks/useSession';
import {
  COMMUNITY_URL,
  EXPERT_DIRECTORY_URL,
  KNOWLEDGE_BASE_URL,
  KNOWLEDGE_BASE_URL_NEXT_GEN
} from '@/utils/constants';
import { isFlagEnabled } from '@/utils/flagsmith';
import { NavItem } from './NavItem';

export interface LinkItem {
  route?: string;
  i18nKey: string;
  icon?: IconType;
  children?: LinkItem[];
  class?: string;
  dataTestId?: string;
}

export type ReadonlyLinkItem = Readonly<LinkItem>;

const getMainNavItems = (hasOnlyNextGenAccess = false) => {
  const mainNavItems: ReadonlyLinkItem[] = [
    {
      route: Route.Apps,
      i18nKey: 'navigation.apps',
      icon: AppsIcon,
      dataTestId: 'sidenav-apps-item'
    },
    {
      i18nKey: 'navigation.settings',
      dataTestId: 'sidenav-settings-item',
      children: [
        {
          route: `${Route.Settings}/${Route.Account}`,
          i18nKey: 'navigation.account',
          icon: AccountIcon,
          dataTestId: 'sidenav-account-item'
        },
        {
          route: `${Route.Settings}/${Route.Security}`,
          i18nKey: 'navigation.security',
          icon: SecurityIcon,
          dataTestId: 'sidenav-security-item'
        },
        {
          route: `${Route.Settings}/${Route.Billing}`,
          i18nKey: 'navigation.billing',
          icon: BillingIcon,
          dataTestId: 'sidenav-plans-item'
        }
      ]
    },
    {
      i18nKey: 'navigation.resources',
      dataTestId: 'sidenav-resources-item',
      children: [
        {
          route: `${Route.Resources}/${Route.GettingStarted}`,
          i18nKey: 'navigation.getting_started',
          icon: GettingStartedIcon,
          dataTestId: 'sidenav-getting-started-item'
        },
        {
          route: hasOnlyNextGenAccess ? KNOWLEDGE_BASE_URL_NEXT_GEN : KNOWLEDGE_BASE_URL,
          i18nKey: 'navigation.help_articles',
          icon: HelpArticlesIcon,
          dataTestId: 'sidenav-help-articles-item'
        },
        {
          route: EXPERT_DIRECTORY_URL,
          i18nKey: 'navigation.hire_an_expert',
          icon: HireAnExpertIcon,
          dataTestId: 'sidenav-hire-an-expert-item'
        },
        {
          route: COMMUNITY_URL,
          i18nKey: 'navigation.community',
          icon: CommunityIcon,
          dataTestId: 'sidenav-community-item'
        }
      ]
    }
  ];

  return mainNavItems;
};

export function MainNav({ isMobile }: { isMobile?: boolean }) {
  const isSharedBuilderOnly = useIsSharedBuilderOnly();

  const mainNavItems = getMainNavItems(isFlagEnabled('only_nextgen_access'));

  const filteredItems = mainNavItems.map((item) => {
    if (item.children && isSharedBuilderOnly) {
      return {
        ...item,
        children: item.children.filter((childItem) => childItem.i18nKey !== 'navigation.billing')
      };
    }
    return item;
  });

  return (
    <nav aria-label="Main menu" className={isMobile ? '' : 'overflow-y-auto'}>
      <ul>
        {filteredItems.map((item) => (
          <NavItem key={item.i18nKey} item={item} />
        ))}
      </ul>
    </nav>
  );
}
