import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KnackLogo,
  Progress,
  ThemeProviderContext,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { cn } from '@/utils/tailwind';
import { WizardLayout } from '@/components/layout/WizardLayout';
import { SETUP_WIZARD_STEPS } from '@/pages/apps/create-app/constants';
import { translationKeyPrefixVerticalUI } from '@/pages/apps/create-app/setup-wizard-variations/constants';
import { StepSelectCardVerticalUI } from './StepSelectCardVerticalUI';
import { StepSelectIndustryVerticalUI } from './StepSelectIndustryVerticalUI';

export function SetupWizardStepsVerticalUI() {
  const [t] = useTranslation();
  const [currentStep, setCurrentStep] = useState(SETUP_WIZARD_STEPS[0]);
  const [selectedIndustry, setSelectedIndustry] = useState<string>();
  const [selectedUseCase, setSelectedUseCase] = useState<string>();
  const [otherUseCaseDetails, setOtherUseCaseDetails] = useState<string>();
  const { data: sampleApps } = useSampleAppsQuery();
  const [goals, setGoals] = useState<MultiSelectOption[]>([]);
  const { isDarkMode } = useContext(ThemeProviderContext);
  const goBack = () => {
    setCurrentStep(SETUP_WIZARD_STEPS[0]);
  };

  const getIndexOfStepForTexts = (step: string) => SETUP_WIZARD_STEPS.indexOf(step) + 1;

  return (
    <>
      <div className="max-h-6 p-1" data-testid="setup-wizard-steps">
        <div
          className={cn(
            'mb-8 block p-6 text-brand focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400',
            {
              'text-white': isDarkMode
            }
          )}
          aria-label={t('common.homepage')}
        >
          <KnackLogo />
        </div>
      </div>
      <WizardLayout
        contentWidth="full"
        hasHeader={false}
        hasFooter={false}
        dataTestid="setup-wizard-layout"
      >
        <div
          className="mx-auto mb-10 flex max-h-[740px] w-full max-w-[580px] flex-col items-start"
          data-testid="setup-wizard-content"
        >
          <div className="my-4 w-full">
            <Progress
              className="mb-6 max-h-2"
              intent="brand"
              value={SETUP_WIZARD_STEPS.indexOf(currentStep) * (100 / SETUP_WIZARD_STEPS.length)}
              data-testid="progress-bar"
            />
          </div>

          <div className="w-full">
            <div className="text-3xl font-medium text-emphasis" data-testid="setup-wizard-title">
              <h1>
                {t(
                  `${translationKeyPrefixVerticalUI}.step_${getIndexOfStepForTexts(currentStep)}.title`,
                  {
                    portal:
                      selectedUseCase && selectedUseCase !== t('common.other')
                        ? t(selectedUseCase)
                        : ''
                  }
                )}
              </h1>
            </div>
            {currentStep === SETUP_WIZARD_STEPS[1] && (
              <div className="mt-4 text-lg font-medium text-subtle" data-testid="wizard-subtitle">
                {t(`${translationKeyPrefixVerticalUI}.step_2.subtitle`)}
              </div>
            )}
          </div>
        </div>
        {currentStep === SETUP_WIZARD_STEPS[0] && (
          <StepSelectIndustryVerticalUI
            setCurrentStep={setCurrentStep}
            selectedIndustry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
            selectedUseCase={selectedUseCase}
            setSelectedUseCase={setSelectedUseCase}
            otherUseCaseDetails={otherUseCaseDetails}
            setOtherUseCaseDetails={setOtherUseCaseDetails}
            goals={goals}
            setGoals={setGoals}
          />
        )}
        {currentStep === SETUP_WIZARD_STEPS[1] && selectedIndustry && selectedUseCase && (
          <StepSelectCardVerticalUI
            selectedIndustry={selectedIndustry}
            selectedUseCase={selectedUseCase}
            sampleApps={sampleApps ?? []}
            onGoBack={goBack}
            data-testid="step-select-card"
          />
        )}
      </WizardLayout>
    </>
  );
}
