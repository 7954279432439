import { useContext, useEffect, useState, type JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import { HiArrowUpTray as ImportIcon, HiSquaresPlus as SampleIcon } from 'react-icons/hi2';
import { MdClose as CloseIcon } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Spinner, ThemeProviderContext } from '@knack/asterisk-react';
import { useLocalStorage } from 'usehooks-ts';

import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSession } from '@/hooks/useSession';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { getErrorMessage } from '@/utils/errors';
import { cn } from '@/utils/tailwind';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import { AppCreateType, BUILDER_NEXT_IMPORT_PATH } from '@/pages/apps/create-app/constants';
import { CreateAppFromSampleModal } from '@/pages/apps/create-app/CreateAppFromSampleModal';

function AppButton({
  icon,
  title,
  description,
  isDisabled,
  onClick,
  dataTestId,
  dataFeatureId
}: {
  icon: JSX.Element;
  title: string;
  description: string;
  isDisabled: boolean;
  onClick: () => void;
  dataTestId?: string;
  dataFeatureId?: string;
}) {
  const { isDarkMode } = useContext(ThemeProviderContext);

  return (
    <Button
      intent="minimal"
      className={cn(
        'group h-auto flex-1 flex-col items-start gap-3 rounded-lg border border-brand-200 px-3 py-4 transition-colors hover:bg-brand-50 focus-visible:bg-action-secondary',
        isDarkMode && 'border-brand-600 hover:bg-brand-900',
        isDisabled && 'border-subtle bg-muted'
      )}
      onClick={onClick}
      data-feature={dataFeatureId}
      data-testid={dataTestId}
      disabled={isDisabled}
    >
      <div className="flex items-center gap-2">
        <div className={cn(isDisabled && 'opacity-75')}>{icon}</div>
        <p
          className={cn(
            'group-hover:text-gradient-1 font-medium',
            isDarkMode && 'group-hover:text-gradient-2'
          )}
        >
          {title}
        </p>
      </div>

      <p className="text-xs font-normal text-subtle">{description}</p>
    </Button>
  );
}

type CreateNewAppProps = {
  isDisabled?: boolean;
  isDismissible: boolean;
  onOpenRestrictionModal: () => void;
  setAppCreationError: (error: string) => void;
};

export function CreateNewApp({
  isDisabled = false,
  isDismissible,
  onOpenRestrictionModal,
  setAppCreationError
}: CreateNewAppProps) {
  const [t] = useTranslation();
  const [shouldShowAppSampleModal, setShouldShowAppSampleModal] = useState(false);
  const { createApp } = useAppMutation();
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [isDismissed, setDismissed] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.createNewAppDismissed,
    false
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const session = useSession();

  useEffect(() => {
    if (searchParams.get('ai')) {
      setShouldShowAiModal(true);

      searchParams.delete('ai');

      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const handleCreateImportApp = () => {
    if (isDisabled) return;

    const data = {
      appDescription: '',
      appOrigin: AppCreateType.Import,
      shouldCreateWithTable: true,
      shouldCreateDefaultUserRoles: true
    };

    createApp.mutate(data, {
      onSuccess: (response) => {
        window.location.href = `${
          import.meta.env.PUBLIC_BUILDER_NEXT_URL
        }/${session.account.slug}/${response.application.slug}/${BUILDER_NEXT_IMPORT_PATH}?new=true`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
      }
    });
  };

  return (
    <>
      {!isDismissed && (
        <Card className="relative flex flex-col gap-4 sm:p-6">
          <h3 className="text-xl font-medium text-emphasis">
            {t('components.apps.create_a_new_app')}
          </h3>

          {isDismissible && (
            <Button
              intent="minimalStandalone"
              onClick={() => setDismissed(true)}
              className="absolute right-3 top-3 p-2 opacity-70 transition-opacity hover:opacity-100"
            >
              <Button.Icon icon={CloseIcon} />
            </Button>
          )}

          <div className="grid grid-cols-1 flex-row gap-4 sm:grid-cols-3 lg:flex">
            <AppButton
              icon={
                <AiStarsIcon
                  size={20}
                  className={cn(
                    'rotate-90 fill-[url(#svg-gradient-1)]',
                    createApp.isPending && 'fill-current'
                  )}
                />
              }
              title={t('components.create_app.ai.generate_with_ai')}
              description={t('components.create_app.ai.generate_with_ai_description')}
              onClick={() => (isDisabled ? onOpenRestrictionModal() : setShouldShowAiModal(true))}
              isDisabled={createApp.isPending}
            />
            <AppButton
              icon={
                <SampleIcon
                  size={20}
                  className={cn(
                    'fill-[url(#svg-gradient-1)]',
                    createApp.isPending && 'fill-current'
                  )}
                />
              }
              title={t('components.create_app.start_from_sample_app')}
              description={t('components.create_app.start_from_sample_app_description')}
              onClick={() =>
                isDisabled ? onOpenRestrictionModal() : setShouldShowAppSampleModal(true)
              }
              isDisabled={createApp.isPending}
              dataTestId="starting-create-new-app"
            />
            <AppButton
              icon={
                createApp.isPending ? (
                  <Spinner />
                ) : (
                  <ImportIcon
                    size={20}
                    className={cn(
                      'fill-[url(#svg-gradient-1)]',
                      createApp.isPending && 'fill-current'
                    )}
                  />
                )
              }
              title={t('components.create_app.import_data')}
              description={t('components.create_app.import_data_description')}
              onClick={() => (isDisabled ? onOpenRestrictionModal() : handleCreateImportApp())}
              isDisabled={createApp.isPending}
            />

            <CreateAppFromSampleModal
              open={shouldShowAppSampleModal}
              setIsOpen={setShouldShowAppSampleModal}
            />
          </div>
        </Card>
      )}

      {shouldShowAiModal && <AiModal open onClose={() => setShouldShowAiModal(false)} />}
    </>
  );
}
